<template>
    <div class="mt-5">
      <section class="pb-150 mb-5">
         <div class="container dont-copy">
           <div class="row mt-5 mb-5">
             <div class="page-title mt-5">
                 <h3>COFFEE EXTRACT</h3>
                 <h5>COFFEA ARABICA & CANEPHORA</h5>
             </div>
           </div>
           <div class="row justify-content-center">
             <div class="col-md-10">
                 <div class="row">
                     <div class="col-md-6"><br>
                         <img :src="imageUrl+'new_website/images/botanical-source/coffee-intro.jpg'" class="img-source p-4" alt="coffee arabica indonesia">
                     </div>
                     <div class="col-md-6">
                        <h4 class="mt-5">Introduction</h4>
                        <p>Indonesia is currently the 4th largest producer of coffee in the world after Brazil, Vietnam, and Colombia at approximately 9.3 MILLION MT PER ANNUM</p>
                        <p align="left">Van Aroma's Coffee extract is derived from both Arabica and Robusta beans. While coffee extract, in general, has a sweet, smooth, woody and roasted aroma, Arabica tends to exude these qualities better than Robusta. 
                        <br><br>
                        Fun fact: Coffee beans are generally stocked near the displays at perfumers as it is believed that smelling coffee between fragrance testing can help limit or counter olfactory fatigue.
                        </p>
                     </div>

                     <br><br>
                        <div class="row" style="margin-top: 80px;">
                            <div class="col-md-12">
                                <img :src="imageUrl+'new_website/images/botanical-source/vanilla_map.jpg'" class="img-thumbnail" style="width: 100%" alt="coffee arabica indonesia">
                                
                            </div>
                        </div><!-- / row -->

                     <div class="col-md-12 mt-5">
                         <h4 class="mt-5 mb-5 text-center">How do we get our Coffee Extract?</h4>
                         <p>Indonesia is currently the 4th largest producer of coffee in the world after Brazil, Vietnam, and Colombia at approximately 9.3 MILLION MT PER ANNUM</p>
                         <p class="text-center" style="font-size: 15px;" >Our high-quality ingredients deliver great-tasting extracts. Our Pure Coffee Extract is made with 100% premium, pure ingredients. Add rich, natural coffee flavor to baked goods, frostings and tiramisu recipes.
                         <br>Gluten-free. Non-GMO. No corn syrup.</p>
                         <div class="mt-5">
                             <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/Coffee-1.jpg'" alt="coffee arabica indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 1 <br>SEEDLING</h5>
                                                 <p>Coffee seedlings are planted in large beds in shaded nurseries.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Coffee-2.jpg'" alt="coffee arabica indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 2 <br>PLANTATION</h5>
                                                 <p>Seedlings are planted during the wet season so that the soil remains moist while the roots become firmly established.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Coffee-3.jpg'" alt="coffee arabica indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 3 <br>PLANTATION BEARING FRUIT</h5>
                                                 <p>It usually takes 3 to 4 years for the newly planted coffee trees to bear fruit / ‘coffee cherries.' The beans turns to a bright, dark red color when it is ripe and ready to be harvested.    </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Coffee-4.jpg'" alt="coffee arabica indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 4 <br>HARVESTING</h5>
                                                 <p>In Indonesia, the crop is picked by hand in a labor-intensive process.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Coffee-5.jpg'" alt="coffee arabica indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 5 <br>DRYING THE BEANS</h5>
                                                <p>Coffee beans are sun-dried by spreading them on tables or large floor areas, where they are regularly turned.   </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Coffee-6.jpg'" alt="coffee arabica indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 6 <br>ROASTING THE BEANS</h5>
                                                <p>The beans are roasted at a temperature of about 287 °C and are kept moving to prevent them from burning. They are then packed and shipped to Van Aroma’s facilities.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/Coffee-7.jpg'" alt="coffee arabica indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 7 <br>GRINDING</h5>
                                                <p>Coffee Beans are ground into fine powder, which is then put through the extraction process.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/SolventExtractor.jpg'" alt="coffee arabica indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 8 <br>EXTRACTION</h5>
                                                <p>Hydro-extraction is used to extract the rich essence of Coffee.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
 
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
           </div>
         </div>

         <FaqElement source="3068ac23-ef88-4e33-b494-e3bf7d01d38f"/>

         <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Coffee Arabica Product List</h4>
                    </div>
                    <ProductList source="3068ac23-ef88-4e33-b494-e3bf7d01d38f"/>
                </div>
            </div>
         </div>
         <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
      </section>
    </div>
 </template>
 <style src="@/assets/css/source-detail.css"></style>
 
 <script>
 import ProductList from '@/components/ProductList.vue'
 import FaqElement from '@/components/FaqElement.vue'
 export default {
   name: 'CoffeeArabica',
   data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
   mounted() {
     let oldScript=document.getElementById('mainJS');
     if(oldScript){
       oldScript.remove();
     }
     let mainJS = document.createElement('script')
     mainJS.setAttribute('id', 'mainJS')
     mainJS.setAttribute('src', 'assets/js/main.js')
     document.body.appendChild(mainJS)
 
     let oldPopover= document.getElementById('popoverJS');
     if(oldPopover){
         oldPopover.remove()
     }
     let popoverJS = document.createElement('script')
     popoverJS.setAttribute('id', 'popoverJS')
     popoverJS.setAttribute('src', 'assets/js/reinit.js')
     document.body.appendChild(popoverJS)
 
     document.title = 'Coffee Arabica - Van Aroma';
   }
 }
 </script>
 