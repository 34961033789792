<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>CAJEPUT</h3>
                <h5>Melaleuca leucadendron L. var. cajuputi</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row mb-5">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/intro_cajeput.jpg'" class="img-source p-4" alt="cajeput oil indonesia">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Introduction to Cajeput Oil</h4>
                        <p>Indonesian cajeput oil is produced  locally by farmers on the island of PULAU BURU in MALUKU and in some parts of Central Java</p>
                        <p>Trees yielding this oil are mostly found around the warmer parts of Indonesia, Vietnam, Brunei and East Malaysia where they are specifically found near the shorelines.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 mt-5">
                        <h4>A brief insight into the Cajeput Oil Industry in Indonesia.</h4>
                        <p>Indonesia is one of the largest producers of cajeput oil. It is also the largest consumer of cajeput oil. Primarily used in massage oils, it is also used for its remedial qualities in treating stomach aches, common cold, nausea and insect bites. More than 90% of the oil produced is consumed locally by the pharma industry.</p>
                        <p>Eucalyptus and Cajeput are very similar in their usage depending on where they are found. Eucalyptus is predominantly found and used in China and India. Cajeput is used in Indonesia, Vietnam, and other parts of Southeast Asia. Average Yearly Production Quantity: 325-350 tons per annum.</p>
                    </div>
                    <div class="col-md-6 mt-5">
                       <p class="text-center"><b>Regions of Cultivation</b></p>
                        <div class="mt-2">
                            <img :src="imageUrl+'new_website/images/botanical-source/map-cajeput.jpg'" class="w-100" alt="cajeput oil indonesia">
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 text-center">How do we get our Cajeput Oil ?</h4>
                        <p class="text-center">
                          We get all our Cajeput Oil from local Indonesian farmers and accumulators who harvest and process the leaves through steam distillation to produce a crude form of Cajeput Oil.
                        <br>CAJEPUT DISTILLATION IN 4 STEPS
                        </p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1_6.jpg'" alt="cajeput oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>PLANTATION</h5>
                                                <p>Cajeput trees are evergreen and can reach up to 30 meters in height. They usually have a whitish, spongy bark with a crooked trunk.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2_6.jpg'" alt="cajeput oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>COLECTING LEAVES</h5>
                                                <p>Leaves from the Cajeput tree are collected throughout the year and sent for steam distillation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3_4.jpg'" alt="cajeput oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>DISTILLATION</h5>
                                                <p>
                                                   Steam distillation is used to isolate, separate and purify the oil content from the leaves to provide optimum quality of Cajeput Oil.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4_6.jpg'" alt="cajeput oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>CAJEPUT OIL</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <FaqElement source="4e483368-22be-4f0e-afb1-4b32b5240dd3"/>
         <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Cajeput Product List</h4>
                    </div>
                    <ProductList source="4e483368-22be-4f0e-afb1-4b32b5240dd3"/>
                </div>
            </div>
        </div>
        <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'CajeputPage',
  data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
    }
  },
  components:{
        ProductList,
        FaqElement
    },
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Cajeput Oil Indonesia - Van Aroma';
  }
}
</script>
