<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>CITRONELLA</h3>
                <h5>Cymbopogon winterianus Jowitt</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/pic-citronella-01.jpg'" class="img-source p-4" alt="citronella oil java">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Introduction to Citronella Oil</h4>
                        <p>Citronella oil is obtined primarily from the leaves and stem of the perennial grass "CYMBOPOGON" of the lemongrass family</p>
                        <p>Citronella is commonly used in soaps, candles, perfumes and other products in the cosmetic industry. It is also a plant based, non toxic insect repellant, registered for this use in the United States since 1948.
                        <br>
                        CITRONELLA OIL DISTILLATION IN 5 STEPS
                        </p>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 text-center">How do we get our Citronella Oil ?</h4>
                        <p class="text-center">
                            We get all our Citronella Oil from local Indonesian farmers and accumulators who harvest and process the crop through steam distillation to produce a crude form of Citronella oil.</p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1_1.jpg'" alt="citronella oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>SEEDLINGS</h5>
                                                <p>Citronella is a perennial grass. However, it does not produce viable seeds, therefore the species can be propagated only vegetatively by slips. This is achieved by splitting well-grown clumps.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2_1.jpg'" alt="citronella oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>PLANTING OF BULBS</h5>
                                                <p>Although the plantation of Java Citronella can be initiated anytime during the year, the onset of monsoon is the best time. The slips should be planted at a distance of 60 cm x 60 cm apart.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3_0.jpg'" alt="citronella oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>PLANTATION</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4_1.jpg'" alt="citronella oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 4 <br>COLLECTION OF LEAVES</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4_3.jpg'" alt="citronella oil java">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 5 <br>DISTILLATION</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        
       
        <FaqElement source="fcb77b14-c70c-4a3f-b928-a2f1dc0c591a"/>

        <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Citronella Product List</h4>
                    </div>
                    <ProductList source="fcb77b14-c70c-4a3f-b928-a2f1dc0c591a"/>
                </div>
            </div>
        </div>
        <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'CitronellaPage',
  data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
    }
  },
  components:{
        ProductList,
        FaqElement
    },
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Citronella Java Oil & Citronella Fractions - Van Aroma';
  }
}
</script>
