<template>
  <section class="ls s-py-90 s-py-xl-50 dont-copy">
    <div class="container" id="widget-container">
      <div class="row">
        <div class="col-md-11 col-12">
          <div class="chanel-header">
            <div class="d-inline-block">
              <img :src="chanelThumbnail.url" class="chanel-thumbnail">
            </div>
            <div class="d-inline-block chanel-info">
                <h4>CengkehPedia</h4>
                <div>
                  <span>{{ subscribers }} Subscribers</span>
                  <span class="divider"></span>
                  <span>{{ totalVideo }} Videos</span>
                  <span class="divider"></span>
                  <span>{{ views }} Views</span>
                </div>
                <p class="chanel-description">
                  CengkehPedia is a not-for-profit YouTube channel launched by Van Aroma. CengkehPedia focusses on providing farmers, and distillers of Clove Oil, in-depth explanations/instructional videos for every step of the Clove cultivation process, as well as content dedicated to solving the various issues specific to the Clove crop (Irrigation, Diseases, nutrient deficiencies, land preparation, land management, protection against extreme weather, etc.)
                </p>
            </div>
          </div>
        </div>
        <div class="col-md-1 col-12 subscribe-btn">
          <div class="g-ytsubscribe" data-channelid="UCpiCpJGE23sVafcBcAfe3Ag" data-layout="default" data-theme="dark" data-count="hidden"></div>
        </div>
        <div class="col-12">
          <div class="text-center mt-5 p-5" v-if="getTry >= 5">
              <p>Failed to get request</p>
              <a class="link-reload" href="https://vanaroma.com/nilampedia">Reload Page</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-12 mt-5" v-for="video in videoList" :key="video.id">
          <div class="video-card" v-if="video.id.videoId">
            <iframe class="video-grid" :src="'https://www.youtube.com/embed/'+video.id.videoId" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="row mt-100">
        <div class="col-md-12 text-center">
          <a href="https://www.youtube.com/@cengkehpedia" target="_blank" class="btn-community"><i class="fa-brands fa-youtube"></i> Our Channel</a>
          <a href="https://www.facebook.com/cengkehpedia" target="_blank" class="btn-community mx-2"><i class="fa-brands fa-facebook"></i> Follow Us</a>
          <a href="https://www.facebook.com/groups/397553715443970/user/100067055745007/" target="_blank" class="btn-community"><i class="fa-brands fa-facebook"></i> Our Community</a>
        </div>
      </div>
    </div>
  </section>
  <div class="divider-40 divider-lg-150"></div>
</template>
<style src="@/assets/css/about.css" scoped></style>
<script>
import axios from "axios";
export default {
  name: 'SustainabilityPage',data(){
    return{
      chanel:[],
      chanelStatistics:[],
      chanelThumbnail:[],
      videos:[],
      subscribers:'',
      views:'',
      totalVideo:'',
      getTry:0,
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
      apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)

      let oldJS=document.getElementById('reInit');
      if(oldJS){
        oldJS.remove();
      }
      let reInit = document.createElement('script')
      reInit.setAttribute('id', 'reInit')
      reInit.setAttribute('src', 'https://apis.google.com/js/platform.js')
      document.body.appendChild(reInit)

      this.getVideo()
  },
  methods:{
    getVideo(){
      axios.get(this.apiUrl+'/webx/r_youtube_data/?id=2')      
      .then((response) => {
        if(response.data.data == undefined){
            console.log('retrying to get data')
            if(this.getTry < 5){
                setTimeout(() => {
                    this.getVideo()
                    this.getTry++
                }, 1000);
            }
        }else{
          this.chanel=JSON.parse(response.data.data.data_chanel.replace(/\n/g, '')).items[0].snippet
          this.chanelStatistics=JSON.parse(response.data.data.data_chanel.replace(/\n/g, '')).items[0].statistics
          this.chanelThumbnail=JSON.parse(response.data.data.data_chanel.replace(/\n/g, '')).items[0].snippet.thumbnails.medium
          this.videos=JSON.parse(response.data.data.data_video.replace(/\n/g, '')).items

          this.subscribers=parseInt(this.chanelStatistics.subscriberCount).toLocaleString()
          this.totalVideo=parseInt(this.chanelStatistics.videoCount).toLocaleString()
          this.views=parseInt(this. chanelStatistics.viewCount).toLocaleString()
        }
      })
      .catch((error) => {
          console.log(error)
      });
    }
  },
  computed: {
    videoList: function () {
      return this.videos.filter(v => v.id.kind === 'youtube#video')
    }
  }
}
</script>