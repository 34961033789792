<template>

  <ModalComponent/>
  <TopbarSection/>
  <NavbarSection/>
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component"></component>
      </transition>
    </router-view>
  <FooterSection />
  <CopyRight />
</template>

<script>

import TopbarSection from '@/components/TopbarSection.vue'
import NavbarSection from '@/components/NavbarSection.vue'
import FooterSection from '@/components/FooterSection.vue'
import CopyRight from '@/components/CopyRight.vue'
import ModalComponent from '@/components/ModalComponent.vue'
export default {
  components: {
    TopbarSection,
    NavbarSection,
    FooterSection,
    CopyRight,
    ModalComponent,
  },
}

</script>

<style src="@/assets/css/bootstrap.min.css"></style>
<style src="@/assets/css/animations.css"></style>
<style src="@/assets/vendor/fontawesome/css/all.min.css"></style>
<style src="@/assets/css/icomoon.css"></style>
<style src="@/assets/css/main.css?v=1.1"></style>
<style>
.fade-enter-active{
  transition: all 0.5s ease-in;
}
.certification-img{
  cursor:default !important;
}
.certification-img img{
  max-height: 100px;
  height: 100%;
  width: 80%;
  object-fit: contain;
}
.home-page{
  width: 100% !important;
  overflow-x: hidden !important;
}

</style>