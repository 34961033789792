<template>
    <div class="mt-5">
      <section class="pb-150 mb-5">
         <div class="container dont-copy">
           <div class="row mt-5 mb-5">
             <div class="page-title mt-5">
                 <h3>GINGER</h3>
                 <h5>ZINGIBER OFFICINALE</h5>
             </div>
           </div>
           <div class="row justify-content-center">
             <div class="col-md-10">
                 <div class="row">
                     <div class="col-md-6"><br>
                         <img :src="imageUrl+'new_website/images/botanical-source/pic-ginger-01.jpg'" class="img-source p-4" alt="red ginger oil indonesia">
                     </div>
                     <div class="col-md-6">
                        <h4 class="mt-5">Introduction to Ginger Oil</h4>
                        <p>Ginger is a knotted, thick, beige underground stem also known as rhizome</p>
                        <p align="left">The stem extends roughly 12 inches above ground with long, narrow, ribbed green leaves coupled with white or yellowish-green flowers. Ginger has a slightly biting and hot note. It’s aroma is rich, sweet, warm and has a distinctive woody olfactory note. </p>
                     </div>
                     <div class="col-md-6"><br>
                        <h4 class="mt-5">A brief insight into the Ginger Oil Industry in Indonesia.</h4>
                        <p>Ginger is grown in Java and South Sumatra, and the oil obtained through hydro distillation or stem distillation</p>
                        <p align="left">Fresh Ginger is mainly used in the local Jamu (traditional herbal medicine) Industry. It is also used for flavoring beverages such as ginger tea and ginger flavored coffee which are very popular in Indonesia. 
                        The Ginger Oil on the other hand is used in a wide spread of industries such as Perfumery, Aromatherapy, Cosmetic, Flavour & Fragrance and Food & Beverages. 
                        Unlike some of the other oils, Ginger Oil is a small volume product ( ~ 5-10 tons per annum ).</p>
                     </div>
                     <div class="col-md-6">
                        <br><br>
                        <img :src="imageUrl+'new_website/images/botanical-source/map-ginger.jpg'" class="img-source p-4" alt="red ginger oil indonesia">

                     </div>

                     <div class="col-md-12 mt-5">
                         <h4 class="mt-5 mb-5 text-center">How do we get our Ginger Oil ?</h4>
                         <p class="text-center" >We get all our Ginger Oil from local Indonesian farmers and accumulators who harvest and process the underground stem through steam or hydro distillation to produce a crude form of Ginger Oil.
                            <br>GINGER DISTILLATION IN 4 STEPS</p>
                         <div class="mt-5">
                             <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/step-ginger-1.jpg'" alt="red ginger oil indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 1 <br>PLANTATION</h5>
                                                 <p>Ginger Oil is extracted from Rhizomes grown mainly in Java and South Sumatra.   </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/step-ginger-2.jpg'" alt="red ginger oil indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 2 <br>COLECTING GINGER RHIZOMES</h5>
                                                 <p></p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/step-ginger-3.jpg'" alt="red ginger oil indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 3 <br>DISTILATION</h5>
                                                 <p>Steam distillation is used to isolate, separate and purify the oil content from the leaves to provide optimum quality of Cajeput Oil.  </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/step-ginger-4.jpg'" alt="red Ginger Oil Indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 4 <br>GINGER OIL</h5>
                                                 <p></p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/SolventExtractor_0.jpg'" alt="red ginger oil indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 5 <br>EXTRACTION</h5>
                                                 <p>Solvent Extraction is used to extract the rich essence.  </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
 
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
           </div>
         </div>
         
         <FaqElement source="d63ca4f6-8663-4ffb-95de-fa587880abbf"/>

         <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Red Ginger Product List</h4>
                    </div>
                    <ProductList source="d63ca4f6-8663-4ffb-95de-fa587880abbf"/>
                </div>
            </div>
         </div>
         <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
      </section>
    </div>
 </template>
 <style src="@/assets/css/source-detail.css"></style>
 
 <script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
 export default {
   name: 'GingerPage',
    data(){
         return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
   mounted() {
     let oldScript=document.getElementById('mainJS');
     if(oldScript){
       oldScript.remove();
     }
     let mainJS = document.createElement('script')
     mainJS.setAttribute('id', 'mainJS')
     mainJS.setAttribute('src', 'assets/js/main.js')
     document.body.appendChild(mainJS)
 
     let oldPopover= document.getElementById('popoverJS');
     if(oldPopover){
         oldPopover.remove()
     }
     let popoverJS = document.createElement('script')
     popoverJS.setAttribute('id', 'popoverJS')
     popoverJS.setAttribute('src', 'assets/js/reinit.js')
     document.body.appendChild(popoverJS)
 
     document.title = 'Red Ginger Oil - Van Aroma';
   }
 }
 </script>
 