<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>NUTMEG & MACE</h3>
                <h5>Myristica fragrans Houtt.</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/pic-nutmeg-01.jpg'" class="img-source p-4" alt="nutmeg oil indonesia">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Cultivation of Nutmeg in Indonesia</h4>
                        <p>The region where numeg is cultivated yield varying qualities of numeg oil.</p>
                        <p>Indonesia is currently the largest producer of Nutmeg Oil in the world, providing 75% to 80% of the global supply. It is also one of the top three largest producers of Mace Oil. The three areas where Nutmeg is mainly grown in Indonesia are Java, Aceh in North Sumatra and the Spice Islands in and around Sulawesi. 80% to 90% of the Nutmeg Oil processed is used for flavouring in beverages.</p>
                    </div>
                    <div class="col-md-12 mt-5 mb-5">
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <h4 class="mb-3">Why does the quality of Nutmeg Oil vary across regions?</h4>
                                <p>Aceh and Java produce tender nutmeg while the mature nutmeg from the spice island is mainly cultivated to be used as a spice</p>
                                <p>
                                Nutmeg is indigenous to Indonesia and is cultivated across the archipelago, but harvesting techniques differ in the western and the eastern parts. The tender nutmeg from Aceh and Java result in a higher yield of oil better suited for distillation. 
                                Meanwhile, the Spice Islands / Sulawesi yield mature Nutmeg, which is mainly used as a spice. Only the unusable Nutmeg from these islands, that cannot be used for spices is used to produce Nutmeg Oil. This results in a relatively inferior quality of Nutmeg Oil.</p>
                            </div>
                            <div class="col-md-6">
                                <div class="mt-2">
                                    <img :src="imageUrl+'new_website/images/botanical-source/map-nutmeg.jpg'" class="w-100" alt="nutmeg oil indonesia">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5 mb-5">
                        <div class="row mt-5">

                            <div class="col-md-6">
                                <div class="mt-2">
                                    <img :src="imageUrl+'new_website/images/botanical-source/pic-nutmeg-02.png'" class="w-100" alt="nutmeg oil indonesia">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h4 class="mb-3">Why does Indonesia have two types of Nutmeg production?</h4>
                                <p>During the colonial era, the people in west indonesia taught that nutmag fruits must be picked continously, in order to encourage the trees to keep fruiting all year round.</p>
                                <p>By adopting the tradition of harvesting early and frequently, the people in west Indonesia could steam distill the young tender Nutmeg that produces a high yield of Nutmeg Oil that is best suited for distillation.
                                While the people of East Indonesia were taught to remove the Mace and Nutmeg from the fruit. They were also taught to harvest the Nutmeg only when they have fully matured since it was believed that early cropping results in the death of the tree. These older and ripe fruits are suitable for sale as a spice but are not so good for distilling because of their low oil content.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 text-center">How do we get our Nutmeg Oil ?</h4>
                        <p class="text-center">
                           We get all our Nutmeg Oil from local Indonesian farmers and accumulators who harvest and process the Nutmeg through steam distillation to produce a crude form of Nutmeg Oil.
                        <br>NUTMEG OIL DISTILLATION IN 4 STEPS
                        </p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1.jpg'" alt="nutmeg oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>SEEDLINGS</h5>
                                                <p>Seeds are harvested between June and July. They are sown immediately after harvesting in beds at a spacing of 30 cm and 2.5-5.0 cm deep. Germination commences within 40 days and extends up to 90 days after sowing.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2.jpg'" alt="nutmeg oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>PLANTATION</h5>
                                                <p>Transplanted to poly bags after they turn a year old, seedlings are then transplanted to the main plantation when they turn 18 to 24 months. They attain full bearing stage after 15 to 20 years and may yield produce for around 60 years.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3.jpg'" alt="nutmeg oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>NUTMEG</h5>
                                                <p>
                                                    The fruits take 7 months from flowering to harvest. For the purpose of extracting oil, the tender fruits are picked for higher oil content.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4.jpg'" alt="nutmeg oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 4 <br>DESTILLATION</h5>
                                                <p>
                                                    The extraction occurs in a steam distillation unit where the Tender Nutmeg is further distilled.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step5.jpg'" alt="nutmeg oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>NUTMEG OIL</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <FaqElement source="01cb1ba2-a85f-4525-9f30-aeb4dfca7b28"/>

        <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Nutmeg & Mace Product List</h4>
                    </div>
                    <ProductList source="01cb1ba2-a85f-4525-9f30-aeb4dfca7b28"/>
                </div>
            </div>
        </div>
        <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'NutmagePage',
    data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Nutmeg Oil Java & Mace Oil - Van Aroma';
  }
}
</script>
