<template>
    <section class="s-py-50 error-404 not-found page_404 s-overlay mobile-overlay">
        <div class="container">
            <div class="row">

                <div class="col-sm-12 text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/404.webp">
                    <!-- .page-header -->
                    <div class="page-content">
                        <h4 class="mb-15">oops...</h4>
                        <p class="fw-600">We're sorry, but something went wrong</p>
                        <div class="divider-50"></div>
                        <!-- <p>
                            <a href="./" class="btn btn-maincolor btn-medium">Go to homepage</a>
                        </p> -->
                    </div>
                    <!-- .page-content -->
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: 'PageNotFound',
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', '/assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = '404 - Not Found';
  }
}
</script>
