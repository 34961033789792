<template>
  <section class="page_title ds s-overlay s-py-90 s-py-xl-150">
    <div class="container">
      <div class="row">

        <div class="col-md-12 text-center">
          <h1 class="emptytext">Distribution Network</h1>
        </div>

      </div>
    </div>
  </section>

  <section class="ls s-py-90 dont-copy">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-10">
          <blockquote class="blockquote-theme">
            <p>
              <span class="color-main">Van Aroma</span> has formal agreements with key distributors of flavor and fragrance raw materials globally. <br>
              All distributed products can be supplied with the original seal as well as with our Certificate of Analysis, if required.
            </p>
          </blockquote>
        </div>
      </div>
      <div class="row dont-copy">
        <div class="col-md-12" v-for="(distributorList, country) in distributors" :key="country">
          <div class="row justify-content-center mt-5 mb-5">
            <div class="col-12 text-center distributor-country mb-3">
              <h5>{{ country }}</h5>
            </div>
            <div class="col-md-2" v-for="(distributor,index) in distributorList" :key="index">
                <div class="cards">
                    <div class="card">
                      <img :src="imageUrl+'img/'+distributor.company_logo" class="card__image" alt="" />
                      <div class="card__overlay">
                        <div class="card__header">
                          <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path/></svg>   
                          <div class="card__header-text">
                            {{ distributor.company_name }}
                          </div>
                        </div>
                        <div class="card__description">
                          <a :href="'mailto:'+distributor.email_distributor" v-if="distributor.email_distributor"><i class="fas fa-envelope"></i></a>
                          <router-link :to="'/product-catalog?crossref='+distributor.recid_company" target="_blank"><i class="fa fa-list"></i></router-link>
                          <a :href="'https://wa.me/'+distributor.whatsapp_distributor" v-if="distributor.whatsapp_distributor"><i class="fab fa-whatsapp"></i></a>
                        </div>
                      </div>
                    </div>   
                </div>
            </div>
              
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="ls s-py-90 dont-copy mb-50">
    <div class="row justify-content-center">
        <div class="col-md-8 text-center">
          <hr>
          <div class="divider-40"></div>
          <p>
          At <b>Van Aroma</b>, our commitment to global outreach drives our business forward. With a steadfast focus on delivering quality products, we have established a strong presence in international markets. Our relentless pursuit of excellence has enabled us to forge valuable partnerships and extend our reach to diverse corners of the world. Join us on a journey as we explore the countries that benefit from our products, and discover the unique ways in which we contribute to global trade and collaboration.
          </p>
          <h4 class="special-heading mt-5">
              <span style="text-transform: uppercase;">WE SHIP OUR PRODUCTS TO</span>
          </h4>
          <div class="divider-40"></div>
        </div>
        <div class="col-md-8 text-center">
          <div class="country-box" @click="toggle($event,country.ports)" v-for="(country,index) in exportCountries" :key="index">
            <div class="country-name">{{ country.emoji }} {{ country.country }}</div>
            <p class="total-customer">{{  parseInt(country.total_company)+5 }} Customers</p>
          </div>
        </div>
        <OverlayPanel ref="op">
          <div class="port-container">
            <div class="port-detail">
              <div class="port-title">
                Destination Port
              </div>
              <ul>
                  <li v-for="(port,index) in selectedPorts" :key="index">{{ port.port_name }} - {{ port.port_type }}</li>
              </ul>
            </div>
          </div>
        </OverlayPanel>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import OverlayPanel from 'primevue/overlaypanel';
export default {
  name: 'DistributionNetwork',
  data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
      apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
      isLoading:false,
      getTry:0,
      emptyResult:false,
      distributors:[],
      isLoadingCountry:false,
      getTryCountry:0,
      exportCountries:[],
      selectedPorts:''
    }
  },
  components : {
    OverlayPanel
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'Distribution Network - Van Aroma';
      this.getDistributionNetwork()
      this.getExportCountry()
  },
  methods:{
    getDistributionNetwork(){
        this.isLoading = true;
        let reqOptions = {
            url: this.apiUrl+'/webx/r_distribution_network',
            method: "GET",
        }   
        axios.request(reqOptions)
        .then((response) => {
            if(response.data.data == undefined){
                console.log('retrying to get data')
                if(this.getTry < 5){
                    setTimeout(() => {
                        this.getDistributionNetwork()
                        this.getTry++
                    }, 1000);
                }
            }else{
                if(Object.keys(response.data.data).length !== 0){
                    this.distributors=response.data.data
                    this.emptyResult=false
                }else{
                  this.emptyResult=true
                }
            }
            this.isLoading = false;
        })
        .catch((err) => {
            console.error(err) 
        });
    },
    getExportCountry(){
        this.isLoadingCountry = true;
        let reqOptions = {
            url: this.apiUrl+'/webx/r_export_country',
            method: "GET",
        }   
        axios.request(reqOptions)
        .then((response) => {
            if(response.data.data == undefined){
                console.log('retrying to get country')
                if(this.getTryCountry < 5){
                    setTimeout(() => {
                        this.getDistributionNetwork()
                        this.getTryCountry++
                    }, 1000);
                }
            }else{
                if(Object.keys(response.data.data).length !== 0){
                    this.exportCountries=response.data.data
                }else{
                  this.exportCountries='';
                }
            }
            this.isLoadingCountry = false;
        })
        .catch((err) => {
            console.error(err) 
        });
    },
    toggle(event,ports) {
      this.$refs.op.toggle(event);
      this.selectedPorts=ports
    }
  }
}
</script>
<style scoped>
.distributor-country h5{
  font-size: 21px;
}
.distributor-box img{
  width: 100%;
  height: 100px;
  object-fit: contain;
  margin: 20px 0px 0px 0px;
  padding: 5px;
}
.distributor-box{
  border: 1px solid #ccc;
  transition: 0.3s all ease-in-out;
  margin: 0px 10px;
  padding: 5px;
  border-radius: 15px;
}
.distributor-box:hover{
  transform: scale(1.05);
}
.distributor-box-footer{
  text-align: center;
  padding: 10px;
}
.distributor-box-footer a{
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 50%;
  line-height: 35px ;
  text-align: center;
  background-color: #2d3546;
  color:#fff;
  margin: 2px;
}
.distributor-box-footer a:hover{
  background-color: #191e29;
}

.card {
  position: relative;
  display: block;
  height: 100%;  
  border-radius: 15px;
  overflow: hidden;
  text-decoration: none;
}

.card__image {      
    width: 100%;
    height: 200px;
    padding: 50px;
    object-fit: contain;
    margin-bottom: 65px;
}
@media (max-width: 575px) {
  .card__image {      
      width: 100%;
      height: 120px;
      padding: 30px;
  }
  .card__description a{
    width: 40px !important;
    font-size: 16px !important;
    margin-right: 10px !important;
  }
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: 25px;    
  background-color: #2d3546;      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em 2em;
  padding-bottom: 1em;
  border-radius: 15px 0 0 0;    
  background-color: #2d3546;
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: #2d3546;
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #fff;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
} 
.card__description a{
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 50%;
  line-height: 35px ;
  text-align: center;
  background-color: #fff;
  color:#2d3546;
  margin: 0px 5px;
}
.card__header-text{
  color: #fff;
  font-weight: bold;
}
.country-box{
  display: inline-block;
  padding: 10px;
  width: 220px;
  border: 1px solid #8b909c;
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.country-name{
  font-size: 14px;
  font-weight: bold;
}
.country-box:hover{
  transform: scale(1.05);
}
.total-customer{
  font-size: 11px;
}
.port-detail{
  font-size: 14px;
  max-height: 300px;
  overflow-y: auto;
}
.port-title{
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #ccc;
}
.port-container {
    padding: 1.25rem !important;
    margin-top: 10px !important;
    background: #fff !important;
    color: #4b5563 !important;
    border: 0 none !important;
    border-radius: 6px !important;
    box-shadow: 0 1px 3px rgba(0,0,0,.3) !important;
}
.port-container:before {
    border-width: 10px !important;
    margin-left: -10px !important;
}
.port-container:before {
    bottom: 100% !important;
    left: calc(var(--overlayArrowLeft, 0) + 1.25rem) !important;
    content: ' ' !important;
    height: 0 !important;
    width: 0 !important;
    position: absolute !important;
    pointer-events: none !important;
}
.port-container:before {
    border: solid transparent !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #f2f2f2 !important;
}
</style>