<template>
  
  <!--Location-->
  <section class="ls s-py-90">
    <div class="container" v-if="getTry >= 5">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center mt-5 p-5">
              <p>Failed to get request</p>
              <a class="link-reload" href="https://vanaroma.com/media">Reload Page</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-md-8" v-if="emptyResult">
          <img :src="imageUrl+'new_website/images/search.png'" class="not-found-small-icon mt-5">
          <div class="text-center text-dark-grey">Ups!... no results found</div>
        </div>
        <div class="col-md-8">
          <article class="vertical-item content-half-padding post type-post status-publish format-standard has-post-thumbnail featured-post" v-for="post in featured" :key="post.slug">
               <div class="item-media cover-img-full">
                <img :src="imageUrl+'new_website/blog/'+post.cover" alt="img">
                <div class="media-links">
                  <div class="links-wrap">
                    <a class="link-anchor" :href="'/post/'+post.slug"></a>
                  </div>
                </div>
              </div>
              <div class="item-content">
                <h6>
                  <a :href="'/post/'+post.slug" class="post-link">
                    {{ post.title }}
                  </a>
                </h6>
              </div>
            </article>
        </div>
        <div class="col-md-4">
          <div class="widget widget_recent_posts">
            <h4>Latest Posts</h4>
            <hr>
            <ul class="list-unstyled">
              <li class="d-flex media" v-for="post in newPosts" :key="post.slug">
                <a class="media-image latest-post" :href="'/post/'+post.slug">
                  <img :src="imageUrl+'new_website/blog/'+post.cover" alt="img">
                </a>
                <div class="media-body">
                  <p class="item-meta">
                   {{post.post_date}}
                  </p>
                  <h4 class="mt-0 latest-post-title">
                    <a :href="'/post/'+post.slug" class="post-link">{{ post.title }}</a>
                  </h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr>
      <div class="row my-5" v-if="posts">
        <div class="col-md-12">
          <h4 class="widget-title">Other Posts</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 my-2" v-for="post in posts" :key="post.slug">
          <article class="vertical-item content-half-padding post type-post status-publish format-standard has-post-thumbnail post-grid">
            <div class="item-media cover-img-full">
              <img :src="imageUrl+'new_website/blog/'+post.cover" alt="img">
              <div class="media-links">
                <div class="links-wrap">
                  <a class="link-anchor" title="" :href="'/post/'+post.slug"></a>
                </div>
              </div>
            </div>
            <div class="item-content">
              <h6>
                <a :href="'/post/'+post.slug" class="post-title">
                  {{ post.title }}
                </a>
              </h6>
              <div class="entry-meta mb-0">
                <span class="entry-author-wrap">
                  <span class="vcard author">
                    <a href="#" title="Posts by admin" rel="author">
                      <span> {{post.post_date}}</span>
                    </a>
                  </span><!-- .author -->
                </span>
              </div>
            </div>
          </article><!-- #post-## -->
        </div>
      </div>
    </div>
  </section>
  <!--Posts End-->
</template>
<script>
import axios from "axios";
export default {
  name: 'MediaView',
  data() {
      return { 
        apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
        newPosts:[],
        featured:[],
        posts:[],
        emptyResult:false,
        getTry:0,
      }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'Media - Van Aroma';
      this.getNewPosts()
      this.getFeatured()
      this.getPosts()
  },
  methods:{
      getNewPosts(){
        let reqOptions = {
            url: this.apiUrl+'/webx/get_posts?limit=4&offset=1&order_by=post_date&order_type=desc',
            method: "GET",
        }      
        axios.request(reqOptions)
            .then((response) => {
              if(response.data.data == undefined){
                  console.log('retrying to get new data')
                  if(this.getTry < 5){
                      setTimeout(() => {
                          this.getNewPosts()
                          this.getTry++
                      }, 1000);
                  }
              }else{
                if(Object.keys(response.data.data.results).length !== 0){
                  this.newPosts=response.data.data.results
                }
              }

            })
            .catch((error) => {
              console.log(error)
              this.newPosts=[]
            }
        );
      },
      getFeatured(){
        let reqOptions = {
            url: this.apiUrl+'/webx/get_posts?limit=1&offset=0&order_by=post_date&order_type=desc',
            method: "GET",
        }      
        axios.request(reqOptions)
            .then((response) => {
              if(response.data.data == undefined){
                  console.log('retrying to get featured')
                  if(this.getTry < 5){
                      setTimeout(() => {
                          this.getFeatured()
                          this.getTry++
                      }, 1000);
                  }
              }else{
                if(Object.keys(response.data.data.results).length !== 0){
                  this.featured=response.data.data.results;
                }else{
                  this.emptyResult=true
                }
              }
            })
            .catch((error) => {
              console.log(error)
              this.emptyResult=true
              this.featured=[]
            }
        );
      },
      getPosts(){
        let reqOptions = {
            url: this.apiUrl+'/webx/get_posts?limit=10&offset=5&order_by=post_date&order_type=desc',
            method: "GET",
        }      
        axios.request(reqOptions)
            .then((response) => {
              if(response.data.data == undefined){
                  console.log('retrying to get post data')
                  if(this.getTry < 5){
                      setTimeout(() => {
                          this.getPosts()
                          this.getTry++
                      }, 1000);
                  }
              }else{
                  if(Object.keys(response.data.data.results).length !== 0){
                    this.posts=response.data.data.results;
                  }else{
                    this.posts=false
                  }
              }
            })
            .catch((error) => {
              console.log(error)
              this.posts=[]
            }
        );
      }
  }
}
</script>