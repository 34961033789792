<template>
   <div class="faq dont-copy" v-if="!emptyResults">
      <div class="container box py-1">
          <div class="row justify-content-center mt-5 mb-5">
            <div class="col-lg-10">
              <h4 class="special-heading text-center mt-5">
                <span>Got questions? We've got answers</span>
              </h4>
              <div class="divider-100"></div>
              <div id="accordion_faq" role="tablist">
                <div class="card"  v-for="(q, index) in faq" :key="index">
                  <div class="card-header" role="tab" :id="'collapse'+index+'_header'">
                    <h5>
                      <a data-bs-toggle="collapse" :href="'#collapse'+index" class="collapsed" aria-expanded="false" :aria-controls="'collapse'+index"  v-html="q.question">
                      </a>
                    </h5>
                  </div>

                  <div :id="'collapse'+index" class="collapse" role="tabpanel" :aria-labelledby="'collapse'+index+'_header'" data-bs-parent="#accordion_faq">
                    <div class="card-body" v-html="q.answer">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
      </div>
      <div class="container question-form-box">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row mt-4">
              <div class="col-md-7">
                <h5 class="mt-5">Didn't find the answer you were looking for?</h5>
                <p>Send us your question pertaining to this product/botanical source, and we will get back to you as soon as possible.</p>
              </div>
              <div class="col-md-5">
                <div class="question-form">
                  <div class="form-group mb-3">
                    <input type="text" class="form-control" placeholder="Email" v-model="email">
                    <p v-if="error"><small><i>{{ error }}</i></small></p>
                  </div>
                  <div class="form-group mb-3">
                    <textarea class="form-control" placeholder="Your question" v-model="question"></textarea>
                  </div>
                  <div class="form-group mb-3">
                    <button type="button" class="btn btn-darkgrey w-100 g-recaptcha" id="btnQuestion" @click="submitQuestion()"><span>Send question</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
</template>

<script>
import axios from "axios";
import validator from 'validator';
export default {
  name: 'FaqElement',
  props: ["source"],
  data() {
    return {
        faq:'',
        emptyResults:false,
        apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
        email:(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).email : '',
        question: '',
        error:''
    };
  },
  watch: {
        
  },
  methods:{
    getFaq(){
      let formdata = new FormData();
      formdata.append("source", this.source);
      let bodyContent =  formdata;
      let reqOptions = {
          url: this.apiUrl+'/webx/r_faq',
          method: "POST",
          data: bodyContent,
      }      
      axios.request(reqOptions)
          .then((response) => {
            if(Object.keys(response.data.data).length !== 0){
              this.faq=response.data.data
            }else{
              this.emptyResults=true
            }
          })
          .catch((error) => {
            console.log(error)
            this.faq=[]
            this.emptyResults=true
          }
      );
    },
    async submitQuestion() {
      let inputEmail=this.email;
      if(validator.isEmail(inputEmail)){
        this.error=''
        if(this.question == ''){
          return
        }else{
          document.getElementById('btnQuestion').disabled=true;
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded()
          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha('submit')
          // Do stuff with the received token.
          let formdata = new FormData();
          formdata.append("token", token);
          let bodyContent =  formdata;
          let reqOptions = {
            url: this.apiUrl+'/webx/verify_recaptcha',
              method: "POST",
              data: bodyContent,
          }      
          axios.request(reqOptions)
              .then((response) => {
                var res=JSON.parse(response.data.data)
                if(res.success && res.score >= 0.5){
                  this.sendQuestion()
                }else{
                  console.log('reCAPTCHA verification failed')
                  console.log(res)
                }
              })
              .catch((error) => {
                console.log(error)
              }
          );
        }
      }else{
        this.error='Email is not valid'
      }
    },
    sendQuestion(){
      let formdata = new FormData();
      formdata.append("email", this.email);
      formdata.append("question", this.question);
      formdata.append("type",'0');
      formdata.append("recid_product", this.source);
      let bodyContent =  formdata;
      let reqOptions = {
          url: this.apiUrl+'/webx/c_question',
          method: "POST",
          data: bodyContent,
      }      
      axios.request(reqOptions)
          .then((response) => {
            if(response.data.data.status == 'success'){
                  this.error=''
                  this.$swal(
                  {
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your question has been sent',
                    showConfirmButton: false,
                    timer: 2500
                  }
              );
            }else{
                this.$swal(
                  {
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: this.error=response.data.data.message,
                    showConfirmButton: false,
                    timer: 2500
                  }
                );
                
            }
          })
          .catch((error) => {
            this.$swal(
              {
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: error,
                showConfirmButton: false,
                timer: 2500
              }
            );
          }
      );
      document.getElementById('btnQuestion').disabled=false;
      this.question=''
    }
  },
  mounted(){
    this.getFaq()
  }
  
}
</script>
<style scoped>
  .faq{
    margin: 150px 0px !important;
    padding: 100px 0px;
    background-color: #f3f2f2;
  }
  .faq .box{
    background-color: #fff;
  }
  .question-form-box{
    background-color: #27d2a3;
    margin-bottom: -215px;
    padding: 30px 15px;
  }
  .question-form-box p{
    color: #fff;
  }
  .question-form .form-control{
    color: #fff;
  }
  .question-form .form-control::-ms-input-placeholder{
    color: #fff  !important;
  }
  .question-form .form-control::-webkit-input-placeholder{
    color: #fff  !important;
  }
  .question-form .form-control::-moz-input-placeholder{
    color: #fff  !important;
  }
  .btn-darkgrey:hover{
    color: #fff;
  }
</style>