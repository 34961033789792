<template>
  <section class="ls">
      <div class="row">
        <div class="col-md-12" v-if="access_status == '0'">
            <div class="pending-request">
              <img src="https://vanaroma.sgp1.digitaloceanspaces.com/email-illustration.svg">
              <p class="mt-3">Your request for market report has been sent,<br>We will back to you soon.</p>
            </div>
        </div>
        <div class="col-md-12" v-else-if="access_status == 1">
           <iframe :src="report" seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true" v-if="report != ''"></iframe>
            <div style="height: 70vh;padding-top: 50px;" v-else>
                  <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/search.png" class="not-found-small-icon mt-50">
                  <p class="text-center text-dark-grey mt-2">Ups!... results not found</p>
            </div>
        </div>
        <div class="col-md-12" style="height: 70vh;" v-else></div>
          <!-- additional Information -->
          <button type="button" id="modalTrigger" class="btn d-none" data-bs-toggle="modal" data-bs-target="#requestModal">open</button>
          <form method="POST" id="formRequest" @submit.prevent="submitRequest">
              <div class="modal fade" id="requestModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="requestModalTitle" aria-hidden="true" style="max-height:100vh">
                  <div class="modal-dialog modal-sm">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title" id="requestModalTitle">Request Market Report</h5>
                              <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="closeModalRequest"></button>
                          </div>
                          <div class="modal-body">
                              <p class="mb-3">
                                Please fill all information, we will send you email with the link to access documents.
                              </p>
                              <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group modal-form mb-3">
                                        <label>Email</label>
                                        <input type="email" class="form-control" name="email" placeholder="Email" v-model="user.email" required>
                                    </div>
                                    <div class="error-form text-danger" v-if="errorEmail">{{ errorEmail }}</div>
                                    <div class="form-group modal-form mb-3">
                                         <label>Full Name</label>
                                        <input type="text" class="form-control" name="full_name" placeholder="Full Name" v-model="user.full_name" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Department</label>
                                        <input type="text" class="form-control" name="department" placeholder="Department" v-model="user.department" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Company</label>
                                        <input type="text" class="form-control" name="company" placeholder="Company" v-model="user.company" required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group modal-form mb-3">
                                            <label>Industry</label>
                                        <input type="text" class="form-control" name="industry" placeholder="Industry" v-model="user.industry" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Country</label>
                                        <select class="form-control" name="country" v-model="user.country" @change="selectCode()">
                                            <option value="">Select Country</option>
                                            <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Phone / Whatsapp</label>
                                        <div class="row">
                                            <div class="col-3">
                                                <select class="form-control text-left" name="phone_code" v-model="user.phone_code">
                                                    <option value="">Code</option>
                                                    <option v-for="country in countries" :key="country.code" :value="country.dial_code">{{ country.dial_code }}</option>
                                                </select>
                                            </div>
                                            <div class="col-9">
                                                <input type="text" class="form-control" name="phone" placeholder="number" v-model="user.phone" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Company Website</label>
                                        <input type="text" class="form-control" name="website" placeholder="https://" v-model="user.website" required>
                                    </div>
                                </div>
                            </div>
                            <div class="error-form text-danger" v-if="error">{{ error }}</div>
                          </div>
                          <div class="ls modal-footer">
                          <button type="submit" id="btnRequest" class="btn btn-outline-darkgrey w-100"><span>Submit</span></button>
                          </div>
                      </div>
                  </div>
              </div>
          </form>
        </div>
  </section>
  <!--Posts End-->
</template>
<script>
import axios from "axios";
import validator from 'validator';
import countriesJson from '@/assets/countries.json'
export default {
  name: "MarketReport",
  data(){
    return{
      timestamp:Date.now(),
      apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
      user:(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : [],
      access_token: (localStorage.getItem('market_report_access_token')) ? localStorage.getItem('market_report_access_token') : '',
      countries:countriesJson,
      errorEmail:'',
      error:'',
      report:'',
      access_status:''
    }
  },
  methods: {
    selectCode(){
        var country = this.countries.find((country) => country.name === this.user.country);
        this.user.phone_code = country.dial_code;
    },
    submitRequest(){
      //validasi
      let inputEmail=this.user.email;
      if(validator.isEmail(inputEmail)){
        let cekgmail = this.user.email.indexOf("gmail");
        let cekhotmail = this.user.email.indexOf("hotmail");
        let ceklive = this.user.email.indexOf("live");
        let cekyahoo = this.user.email.indexOf("yahoo");
        if (cekyahoo != -1 || ceklive != -1 || cekhotmail != -1 || cekgmail != -1) {
          this.errorEmail='Please use company email'
        }else{
          this.errorEmail=''
          this.sendRequest()
        }
      }else{
        this.errorEmail='Email is not valid'
      }
    },
    sendRequest(){
      document.getElementById('btnRequest').disabled=true;
      let formdata = new FormData();
      formdata.append("email", this.user.email);
      formdata.append("name", this.user.full_name);
      formdata.append("company", this.user.company);
      formdata.append("website", this.user.website);
      formdata.append("phone", this.user.phone);
      formdata.append("department", this.user.department);
      formdata.append("phone_code", this.user.phone_code);
      formdata.append("country", this.user.country);
      formdata.append("industry", this.user.industry);
      formdata.append("status", 0);
      let bodyContent =  formdata;
      let reqOptions = {
          url:  this.apiUrl+"/webx/c_market_report_access",
          method: "POST",
          data: bodyContent,
      }      
      axios.request(reqOptions)
      .then((response) => {
          if(response.data.data.status=='success'){
            this.access_token=response.data.access_token
            localStorage.setItem('market_report_access_token', response.data.access_token)
          }
          document.getElementById('closeModalRequest').click();
          this.alertSuccess('Request has been sent')
      })
      .catch((error)=>{
          console.log(error)
      });
    },
    getAccess(){
      if(this.access_token == ''){
       return false
      }else{
        let formdata = new FormData();
        formdata.append("access_token", this.access_token);
        let bodyContent =  formdata;
        let reqOptions = {
            url:  this.apiUrl+"/webx/r_market_report_access",
            method: "POST",
            data: bodyContent,
        }      
        axios.request(reqOptions)
        .then((response) => {
          console.log(response)
            this.access_status=response.data.data.status
            this.report=response.data.data.link
        })
        .catch((error)=>{
            console.log(error)
        });
      }
    },
    alertSuccess(message) {
      this.$swal(
            {
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: message,
            showConfirmButton: false,
            timer: 2500
            }
        );
    },
    showForm(){
      if(this.access_token == ''){
        document.getElementById('modalTrigger').click();
      }
    }
  },
  computed:{
    
  },
  watch: {
    access_token:function(){
      this.showForm()
      this.getAccess()
    },
    access_status:function(val){
      if(val==2){
        localStorage.setItem('market_report_access_token', '')
        this.access_token=''
      }
    }
        
  },
  mounted() {
    let oldScript = document.getElementById("mainJS");
    if (oldScript) {
      oldScript.remove();
    }
    let mainJS = document.createElement("script");
    mainJS.setAttribute("id", "mainJS");
    mainJS.setAttribute("src", "assets/js/main.js");
    document.body.appendChild(mainJS);
    document.title = "Pricelist - Van Aroma";
    this.showForm()
    this.getAccess()
    
  },
};
</script>
<style scoped>
iframe{
  width: 100%;
  height: 100vh;
}
.select_container select{
	text-align: left;
}
.error-form{
  font-size: 11px;
  margin-top: -10px;
}
.pending-request{
  text-align: center;
  height: 80vh;
}
.pending-request img{
  width: 300px;
  margin-top: 15vh;
}
@media (max-width: 768px) {
}
</style>
