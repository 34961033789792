<template>
  <div>
    <section class="page_topline ls ms c-my-10">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-12 d-flex">
            <div class="me-auto me-4">
              <span class="social-icons">
                <span class="color-dark d-none d-md-inline small-text me-4">
                  Follow us:
                </span>

                <a href="https://www.linkedin.com/company/vanaroma" class="fa fa-linkedin @@iconClass" title="linkedin" target="_blank"></a>
                <a href="https://www.facebook.com/vanaromaindonesia" class="fa fa-facebook @@iconClass" title="facebook" target="_blank"></a>
                <a href="https://www.instagram.com/van_aroma/" class="fa fa-instagram @@iconClass" title="instagram" target="_blank"></a>
				<a href="https://linktr.ee/vanaroma" class="@@iconClass" target="_blank"><img class="linktree-icon" src="../assets/linktree.svg" alt="linktree"></a>
              </span>
            </div>
            <div class="d-none d-md-block">
              <div id="topline_dropdown" aria-labelledby="toplineDropdown" class="dropdown meta-dropdown">
                <span id="toplineDropdown" class="nav-btn type-dots dark" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                  <span></span>
                </span>
                <ul class="dropdown-menu dropdown-menu-md site-meta" aria-labelledby="toplineDropdown">
                  <li class="icon-inline">
                    <span class="meta-item"><i class="ico-phone"></i><a href="tel:0218677003">(021) 867 7003</a></span>
                  </li>
                  <li class="icon-inline">
                    <span class="meta-item"><i class="fa fa-envelope"></i><a href="mailto:info@vanaroma.com<">info@vanaroma.com</a></span>
                  </li>
                  <!-- <li class="icon-inline d-none d-md-block">
                    <span class="meta-item"><i class="ico-pin"></i>Indonesia</span>
                  </li> -->
                </ul>
              </div>
            </div>

            <div class="search-bar d-md-none">
				<div id="search-wrap" class="search-wrap">
					<form action="/product-catalog" autocomplete="on">
						<input name="search" type="text" placeholder="search.." v-model="query" v-debounce:1000ms="search" @blur="doneSearch()">
						<span class="search-icon"><i class="fa fa-search"></i></span>
					</form>
				</div>
				<div class="search-suggest">
					<ul id="searchBarMobile">
						<li v-if="isTyping" class="text-center loading"><img src="@/assets/img/loading.gif" ></li>
						<li v-if="emptyResults" class="text-center">No results</li>
						<li v-for="result in searchResults" :key="result.title" @click="clickResult(result.url)">
							<div class="row">
								<div class="col-3">
									<div class="thumbnail">
										<img :src="result.thumbnail">
									</div>
								</div>
								<div class="col-9">
									<p class="title">{{ result.title }}</p>
									<p class="subtitle">{{ result.subtitle }}</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<!-- <div class="d-md-none cart-icon">
				<a href="#" @click="setShowCartContent(1)" data-bs-toggle="modal" data-bs-target="#modalOffer">
					<i class="fa fa-shopping-cart" aria-hidden="true"></i>
					<span class="cart-label" id="totalCart">{{ (totalCart > 0) ? totalCart : '' }}</span>
				</a>
			</div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { vue3Debounce } from 'vue-debounce'
import { mapGetters,mapActions } from "vuex"
export default {
  name: 'TopbarSection',
  directives: {
		debounce: vue3Debounce({ lock: true })
  },
  data() {
		return {
			apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
			searchResults:[],
			isTyping:false,
			emptyResults:false,
			query:'',
		}
	},
	methods:{
		search(){
			if(this.query){
				if(this.query.length > 3){
					this.isTyping=true;
					let formdata = new FormData();
					formdata.append("query", this.query);
					let bodyContent =  formdata;
					let reqOptions = {
						url: this.apiUrl+'/webx/search',
						method: "POST",
						data: bodyContent,
					}      
					axios.request(reqOptions)
						.then((response) => {
							if(Object.keys(response.data.data).length !== 0){
								this.searchResults=response.data.data
								this.isTyping=false;
								this.emptyResults=false
							}else{
								this.searchResults=[];
								this.emptyResults=true
								this.isTyping=false;
							}
							// console.log(response)
						})
						.catch((error) => {
							console.log(error)
							this.searchResults=[]
							this.emptyResults=true
							this.isTyping=false;
						}
					);
				}
			}else{
				this.isTyping=false;
				this.emptyResults=false
				this.searchResults=[];
			}
		},
		doneSearch(){
			this.query='';
		},
		clickResult(url){
			this.$router.push(url)
			this.searchResults=[];
		},
		...mapActions({
			setShowCartContent:"products/setShowCartContent",
		}),
		
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		},
		...mapGetters({
            selectedProducts:"products/selectedProducts",
        }),
		totalCart(){
			return this.selectedProducts.length
		},
	},
	mounted(){
		var self = this
		window.addEventListener('click', function(e){   
			if (!document.getElementById('searchBarMobile').contains(e.target)){
				self.searchResults=[];
			} 
		});
	}
}
</script>
<style scoped>
.container-fluid{
	padding-right: 1.75rem;
	padding-left: 1.75rem;
}
.icon-inline > * {
margin: 0;
}
.search-bar{
	position: relative;
	margin-right: 10px;
}
#search-wrap {
  position: relative;
  padding: 0;
}

.search-bar input[type="text"] {
  display: inline-block;
  border: none;
  outline: none;
  color: #555;
  padding: 3px;
  padding-right: 60px;
  width: 0px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  z-index: 3;
  height: 30px;
  transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
  /* transition-timing-function: ease-out; */
  cursor: pointer;
}

.search-bar input[type="text"]:focus:hover {
  border-bottom: 1px solid #BBB;
}

.search-bar input[type="text"]:focus {
  width: 200px;
  z-index: 1;
  border-bottom: 1px solid #BBB;
  cursor: text;
}
.search-icon {
  display: inline-block;
  float: right;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 1;
  color: #696e7c;
  cursor: pointer;
  transition: opacity .4s ease;
}

.search-icon:hover {
  opacity: 0.5;
}
.search-suggest{
	position: absolute;
	right: 0;
	top: 15px;
	width: 300px;
	right: 20px;
}
.search-suggest ul:empty {
   display: none;
}
.search-suggest ul{
  top: 20px;
  width: 100%;
  position: absolute;
	max-height: 250px;
	overflow-y: auto;
	background-color: #f8f9f9;
	padding: 10px;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	list-style: none !important;
  z-index: 9999;
}
.search-suggest ul::-webkit-scrollbar {
  display: none;
}

.search-suggest ul li{
	background-color: #fff;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 12px;
	padding: 10px;
	text-align: left;
	width: 100%;
	line-height: 18px;
	word-wrap: break-word;      /* IE 5.5-7 */
	white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
	white-space: pre-wrap;      /* current browsers */
}
.search-suggest ul li .thumbnail img{
	width: 100%;
	border-radius: 5px;
}
.search-suggest ul li .title{
	margin: 0px;
	font-size: 13px;
	font-weight: bold;
}
.cart-icon{
	margin-left: 10px;
	position: relative !important;
}
.cart-label{
	display: block;
    background-color: #ed1f1f;
    color: #fff;
    position: absolute;
    top: -5px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    left: -15px;
    line-height: 20px;
}
.cart-label:empty{
	display: none;
}

.linktree-icon {
	width: 1.5%;
}

@media only screen and (max-width: 768px) {
	.linktree-icon {
		width: 3.5%;
	}
}

/* Z Fold while unfolded */
@media only screen and (min-width: 680px) and (max-width: 835px) {
	.linktree-icon {
		width: 2%;
	}
}
</style>