<template>
  <div>
    
    <!-- Button trigger modal -->
    <button type="button" id="trigerModal" class="btn d-none" data-bs-toggle="modal" data-bs-target="#modalSubscribe">open</button>
    <!-- Modal -->
    <form method="POST" id="formSubscribe" @submit.prevent="subscribe">
        <div class="modal fade" id="modalSubscribe" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true" style="max-height:100vh">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalTitle">Required Information</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModalSubscribe"></button>
                    </div>
                    <div class="modal-body">
                    <div class="form-group has-placeholder mb-3">
                            <label>Email<span class="required">*</span></label>
                            <input type="text" aria-required="true" size="10" class="form-control" id="subscriberEmail" placeholder="Email" readonly>
                            <span class="border-animate"></span>
                        </div>
                        <div class="form-group has-placeholder mb-3">
                            <label>First Name<span class="required">*</span></label>
                            <input type="text" aria-required="true" size="10" class="form-control" v-model="form.first_name" placeholder="First Name" required>
                            <span class="border-animate"></span>
                        </div>
                        <div class="form-group has-placeholder mb-3">
                            <label>Last Name<span class="required">*</span></label>
                            <input type="text" aria-required="true" size="10" class="form-control" v-model="form.last_name" placeholder="Last Name" >
                            <span class="border-animate"></span>
                        </div>
                        <div class="form-group has-placeholder mb-3">
                            <label>Company<span class="required">*</span></label>
                            <input type="text" aria-required="true" size="10" class="form-control" v-model="form.company" placeholder="Company" required>
                            <span class="border-animate"></span>
                        </div>
                        <div class="form-group has-placeholder mb-3">
                            <label>Country<span class="required">*</span></label>
                            <input type="text" aria-required="true" size="10" class="form-control" v-model="form.country" placeholder="Country" required>
                            <span class="border-animate"></span>
                        </div>
                        <div class="form-group has-placeholder mb-3">
                            <label>Phone Number<span class="required">*</span></label>
                            <input type="text" aria-required="true" size="10" class="form-control" v-model="form.phone_number" placeholder="Phone Number" required>
                            <span class="border-animate"></span>
                        </div>
                    </div>
                    <div class="ls modal-footer">
                    <button type="submit" id="btnConfirmSub" class="btn btn-outline-darkgrey w-100" data-content="Send Message"><span>Continue to subscribe</span></button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="modal fade" id="modalLogin" tabindex="-1" aria-hidden="true" style="max-height:100vh">
        <div class="modal-dialog modal-sm">
            <div class="modal-content mt-5">
                <div class="modal-body mb-2">
                    <h5 class="modal-title text-center w-100 mb-2">Describe who you are...</h5>
                    <select class="form-control" @change="openTab($event)">
                        <option value="">Select</option>
                        <option value="https://portal.vanaroma.com">Existing Customer</option>
                        <option value="https://vendor.vanaroma.com">Existing Supplier</option>
                        <option value="https://regsup.vanaroma.com">New Supplier</option>
                    </select>
                    <div class="form-group mt-5">
                         <button type="button" class="btn btn-xs w-100 btn-outline-darkgrey" data-bs-dismiss="modal" aria-label="Close" id="closeModalLogin">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalOffer" tabindex="-1" aria-labelledby="modalHeaderTwo" aria-hidden="true" style="max-height:100vh">
        <div class="modal-dialog">
            <div class="modal-content mt-5">
                <div class="modal-header d-block">
                    <div class="row">
                        <div class="col-3">
                             <button class="btn btn-clear-selection d-md-none"  @click.prevent="clearSelectedProduct()">Clear</button>
                              <button class="btn btn-clear-selection d-none d-md-block"  @click.prevent="clearSelectedProduct()">Clear Selection</button>
                        </div>
                        <div class="col-6">
                            <h5 class="modal-title text-center w-100 mb-2" id="modalHeaderTwo">Your Selection</h5>
                        </div>
                        <div class="col-3">
                             <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="closeModalOffer"></button>
                        </div>
                    </div>
                </div>
                <div class="modal-body mb-2 content-offer">
                    <Transition name="slide-fade" mode="out-in">
                    <div v-if="showCartContent == 1">
                        <table class="shop_table shop_table_responsive cart">
                            <thead>
                                <tr>
                                    <th class="text-center" style="width:70px"></th>
                                    <th>Product</th>
                                    <th class="text-center">Qty (kg)</th>
                                    <!-- <th>Packaging</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cart_item" v-for="item in selectedProducts" :key="item.recid_product">
                                    <td class="text-center">
                                        <a href="#" @click.prevent="unselectProduct(item.recid_product)" class="remove"><i class="fa fa-trash"></i></a>
                                    </td>
                                    <td>
                                        <b>{{ item.product_ipc }} - {{ item.product_name }}</b>
                                        <p v-if="item.moq"><small><i v-html="nl2br(item.moq)"></i></small></p>
                                    </td>
                                    <td class="text-center">
                                        <input type="text" tabindex="1" placeholder="0" class="qty offer-qty" @change="editQty($event,item.recid_product)" step="1" min="1" name="qty[]" :value="item.qty" size="4" @keydown="numberFormat($event)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-12">
                            <p class="mb-3"><i>Please fill all required information below</i></p>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group modal-form mb-3">
                                <label>Email</label>
                                <input type="email" class="form-control" name="email" placeholder="Email" v-model="userForm.email" required>
                            </div>
                            <div class="form-group modal-form mb-3">
                                    <label>Full Name</label>
                                <input type="text" class="form-control" name="full_name" placeholder="Full Name" v-model="userForm.full_name" required>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Department</label>
                                <input type="text" class="form-control" name="department" placeholder="Department" v-model="userForm.department" required>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Company</label>
                                <input type="text" class="form-control" name="company" placeholder="Company" v-model="userForm.company" required>
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="form-group modal-form mb-3">
                                    <label>Industry</label>
                                <input type="text" class="form-control" name="industry" placeholder="Industry" v-model="userForm.industry" required>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Country</label>
                                 <select class="form-control" name="country" v-model="userForm.country" @change="selectCode()">
                                    <option value="">Select Country</option>
                                    <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
                                 </select>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Phone / Whatsapp</label>
                                <div class="row">
                                    <div class="col-3">
                                        <select class="form-control" name="phone_code" v-model="userForm.phone_code">
                                            <option value="">Code</option>
                                            <option v-for="country in countries" :key="country.code" :value="country.dial_code">{{ country.dial_code }}</option>
                                        </select>
                                    </div>
                                    <div class="col-9">
                                        <input type="text" class="form-control" name="phone" placeholder="number" v-model="userForm.phone" required>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Company Website</label>
                                <input type="text" class="form-control" name="website" placeholder="https://" v-model="userForm.website" required>
                            </div>
                        </div>
                    </div>
                    </Transition>
                </div>
                <div class="modal-footer d-block">
                    <div class="row">
                        <div class="col-md-6 col-7">
                             <Transition name="slide-fade" mode="out-in">
                                 <p v-if="showCartContent == 1" style="font-size:12px;line-height: 1.5;"><i>Note : <br>Van Aroma is a bulk producer, and exporter, and we do not typically supply volumes under our item MOQ.</i></p>
                                <button type="button" v-else class="btn-offer btn-outline-darkgrey mx-2" @click="showCart()">Back</button>
                             </Transition>
                        </div>
                        <div class="col-md-6 col-5 text-end">
                            <button type="button" id="btnSendOffer" class="btn-offer btn-outline-darkgrey" @click="checkData()" :disabled="selectedProducts == ''">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="modalSample" tabindex="-1" aria-hidden="true" style="max-height:100vh">
        <div class="modal-dialog modal-sample">
            <div class="modal-content mt-5">
                <div class="modal-header d-block">
                    <div class="row">
                        <div class="col-3">
                              <button class="btn btn-clear-selection"  @click.prevent="clearSelectedSample()">Clear</button>
                        </div>
                        <div class="col-6">
                            <h5 class="modal-title text-center w-100 mb-2">Request Sample</h5>
                        </div>
                        <div class="col-3">
                             <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="closeModalSample"></button>
                        </div>
                    </div>
                </div>
                <div class="modal-body mb-2 content-offer">
                    <Transition name="slide-fade" mode="out-in">
                    <div v-if="showSampleContent == 1">
                        <table class="shop_table shop_table_responsive cart">
                            <thead>
                                <tr>
                                    <th class="text-center" style="width:70px;vertical-align: middle;"></th>
                                    <th style="vertical-align: middle;">Product</th>
                                    <th class="text-center" style="vertical-align: middle;">Qty (ml)</th>
                                    <!-- <th>Packaging</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cart_item" v-for="item in selectedSamples" :key="item.recid_product">
                                    <td class="text-center" style="vertical-align: middle;">
                                        <a href="#" @click.prevent="unSelectSample(item.recid_product)" class="remove"><i class="fa fa-trash"></i></a>
                                    </td>
                                    <td style="vertical-align: middle;">
                                        {{ item.product_ipc }} - {{ item.product_name }}
                                    </td>
                                    <td class="text-center sample-qty">
                                        <select class="form-control text-center" @change="editSampleQty($event,item.recid_product)">
                                            <option value="15" :selected="item.qty == 15">15</option>
                                            <option value="30" :selected="item.qty == 30">30</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-12">
                            <p class="mb-3"><i>Please fill all required information below</i></p>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group modal-form mb-3">
                                <label>Email</label>
                                <input type="email" class="form-control" name="email" placeholder="Email" v-model="userForm.email" required>
                            </div>
                            <div class="form-group modal-form mb-3">
                                    <label>Full Name</label>
                                <input type="text" class="form-control" name="full_name" placeholder="Full Name" v-model="userForm.full_name" required>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Department</label>
                                <input type="text" class="form-control" name="department" placeholder="Department" v-model="userForm.department" required>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Company</label>
                                <input type="text" class="form-control" name="company" placeholder="Company" v-model="userForm.company" required>
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="form-group modal-form mb-3">
                                    <label>Industry</label>
                                <input type="text" class="form-control" name="industry" placeholder="Industry" v-model="userForm.industry" required>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Country</label>
                                 <select class="form-control" name="country" v-model="userForm.country" @change="selectCode()">
                                    <option value="">Select Country</option>
                                    <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
                                 </select>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Phone / Whatsapp</label>
                                <div class="row">
                                    <div class="col-2">
                                        <select class="form-control" name="phone_code" v-model="userForm.phone_code">
                                            <option value="">Code</option>
                                            <option v-for="country in countries" :key="country.code" :value="country.dial_code">{{ country.dial_code }}</option>
                                        </select>
                                    </div>
                                    <div class="col-10">
                                        <input type="text" class="form-control" name="phone" placeholder="number" v-model="userForm.phone" required>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group modal-form mb-3">
                                <label>Company Website</label>
                                <input type="text" class="form-control" name="website" placeholder="https://" v-model="userForm.website" required>
                            </div>
                        </div>
                    </div>
                    </Transition>
                </div>
                <div class="px-3 mt-4 mb-3">
                    <div class="form-group modal-form text-center">
                        <label>Please share your courier account details</label>
                        <input type="text" class="form-control text-center" name="courier_detail" placeholder="Courier Account Details (Optional)" style="border-color: transparent;" v-model="courier">
                    </div>
                </div>
                <div class="modal-footer d-block">
                    <div class="row">
                        <div class="col-md-6 col-7">
                        </div>
                        <div class="col-md-6 col-5 text-end">
                            <button type="button" id="btnSampleRequest" class="btn-offer btn-outline-darkgrey" @click="checkDataSample()" :disabled="selectedSamples == ''">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button type="button" id="modalMediaTrigger" class="btn d-none" data-bs-toggle="modal" data-bs-target="#popupMedia">open</button>
    <div class="modal fade" id="popupMedia" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="requestModalTitle" aria-hidden="true" style="max-height:100vh">
        <div class="modal-dialog modal-dialog-centered  modal-sm-popupmedia">
            <div class="modal-content">
                
                
                <div class="modal-body">
                    <h6 class="text-center">{{ popupMedia.title }}</h6>
                    
                    <div class="item-media" v-if="isMediaPopupFound">
                        <img :src="imageUrl+'new_website/blog/'+popupMedia.cover" alt="img" class="post-cover">
                    </div> 
                    <div class="entry-content post-content mt-3" v-html="contentBlog">
                    </div>
                    <div class="row mt-4">
                        <div class="col-6 col-sm-4 mb-2 mb-sm-0">
                            <button type="button" id="btnClosePopUpMedia" class="btn-offer btn-outline-darkgrey" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                        <div class="col-6 col-sm-8 text-end">
                            <button type="button" class="btn-offer btn-outline-darkgrey" @click="openUrl('/post/' + popupMedia.slug)">Read More</button>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
    
    <button type="button" id="btnSetCountryLang" class="btn d-none" data-bs-toggle="modal" data-bs-target="#modalSetCountryLang">open</button>
    <div class="modal fade justify-content-center" id="modalSetCountryLang" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="requestModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mx-auto modal-sm-setlanguage">
            <div class="modal-content">
                
                <div class="modal-body">
                    <div class="form-group modal-form mb-3">
                        <label>Select Country</label>
                        <div class="d-flex justify-content-center">
                            <Dropdown v-model="selectedCountry" filter :options="countryList" optionLabel="name" placeholder="Select a Country" appendTo="self" autoFilterFocus filterPlaceholder="Search a Country" id="selectCountry" class="w-100 z-50 md:w-max sm:w-max m-auto left-0 right-0"  @click="showSelectLanguage(false)" @hide="showSelectLanguage(true)">
                                <template #value="slotProps">
                                    <div v-if="slotProps.value" class="d-flex align-items-center">
                                        
                                        <div>{{ slotProps.value.emoji }} {{ slotProps.value.name }}</div>
                                    </div>
                                    <span v-else>
                                        {{ slotProps.placeholder }}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <div class="d-flex align-items-center">
                                        <div>{{ slotProps.option.emoji }} {{ slotProps.option.name }} </div>
                                    </div>
                                </template>
                            </Dropdown>
                        </div>
                    </div>
                    <div class="form-group modal-form mb-3">
                        <label>Preferred Language</label>
                        <div class="card flex justify-content-center" v-if="showDropdownLanguage">
                            <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" optionValue="code" placeholder="Select a Language" appendTo="self" id="selectLanguage" class="w-full md:w-14rem" />
                        </div>
                        <div class="card flex justify-content-center" v-else>
                            <select v-model="selectedLanguage" class="form-select">
                                <option value="">Select Language</option>
                                <option value="EN">English</option>
                                <option value="ID">Indonesian</option>
                                <option value="CN">Chinese</option>
                                <option value="FR">French</option>
                                <option value="SP">Spanish</option>
                                <option value="JP">Japanese</option>
                                <option value="VN">Vietnamese</option>
                                <option value="TR">Turkish</option>
                                <option value="RU">Russian</option>
                            </select>
                        </div>
                    </div>
                    <div class="float-center">
                        <button type="button" id="btnSendOffer" class="btn-offer btn-outline-darkgrey w-100" @click="saveSettings()" >Submit</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import validator from 'validator';
import { mapGetters,mapActions } from "vuex"
import countriesJson from '@/assets/countries.json'
import countriesFlagJson from '@/assets/countries-with-flag.json'
import Dropdown from 'primevue/dropdown';
// theme
// import '@/assets/css/lara-light-green.css';

import 'primevue/resources/themes/lara-light-green/theme.css'

import { ref } from "vue";
export default {
  name: 'ModalComponent',
  data() {
        return {
          form:{
            email:'',
            first_name:'',
            last_name:'',
            company:'',
            country:'',
            phone_number:''
          },
          error:'',
          userForm:{
                email:'',
                full_name:'',
                department: '',
                company:'',
                website:'',
                phone:'',
                country:'',
                industry:'',
                phone_code:''
          },
          courier:'',
          countries:countriesJson,
            languages: ref([
                { name: '🇬🇧 English', code: 'EN' },
                { name: '🇮🇩 Bahasa Indonesia', code: 'ID' },
                { name: '🇨🇳 Mandarin', code: 'CN' },
                { name: '🇫🇷 French', code: 'FR' },
                { name: '🇪🇸 Spanish', code: 'SP' },
                { name: '🇯🇵 Japanese', code: 'JP' },
                { name: '🇻🇳 Vietnamese', code: 'VN' },
                { name: '🇹🇷 Turkish', code: 'TR' },
                { name: '🇷🇺 Russian', code: 'RU' },
                { name: '🇸🇦 Arabic', code: 'AR' },
            ]),
          apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
          imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://cdn.vanaroma.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
          popupMedia: [],
          isMediaPopupFound: false,

          // Set country and language cookies
          countryList: countriesFlagJson,
        //   countryList: ref([]),
          selectedLanguage: '',
          selectedCountry: '',
          showLanguage : true,
          showDropdownLanguage : true,
          currentCountry : localStorage.getItem('country') ? localStorage.getItem('country') : ''
        }
    },
    components : {
        Dropdown
    },
    mounted() {
        //cek localsotrage expired
        const currentDate = new Date();
        const currentTimeInMillis = currentDate.getTime();
        if(currentTimeInMillis > parseInt(localStorage.getItem('expired_date'))){
            localStorage.removeItem('expired_date') //set expired date for local storage
            localStorage.removeItem('country')
            this.currentCountry=''
        }
        if(this.currentCountry){
            this.getPopupMedia();
        }else{
            this.getCountryInfo()
            this.getClientIp();
        }

        //code versi dafa
        // const value = `; ${document.cookie}`;
        // const parts = value.split(`; settings=`);
        // if (parts.length === 2)  {
        //     this.getPopupMedia();
        // } else {
        //     // this.getCountryCrossref()
        //     // this.sortCountries();
        //     // this.showPopupCountryLanguage(); 
        // }
    },
  methods:{
    alertSuccess() {
      this.$swal(
            {
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Your request is already send',
            showConfirmButton: false,
            timer: 2500
            }
        );
    },
    alertError() {
      this.$swal(
            {
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: 'Failed, something is wrong',
            showConfirmButton: false,
            timer: 2500
            }
        );
    },
    subscribe(){
        document.getElementById('btnConfirmSub').disabled=true;
        let subscriberEmail = document.getElementById('subscriberEmail').value;
        let formdata = new FormData();
        formdata.append("email", subscriberEmail);
        formdata.append("first_name", this.form.first_name);
        formdata.append("last_name", this.form.last_name);
        formdata.append("company", this.form.company);
        formdata.append("country", this.form.country);
        formdata.append("phone_number", this.form.phone_number);
        let bodyContent =  formdata;
        let reqOptions = {
            url: this.apiUrl+"/webx/c_subscriber/",
            method: "POST",
            data: bodyContent,
        }      
        axios.request(reqOptions)
            .then((response) => {
                document.getElementById('btnConfirmSub').disabled=false;
                let formEmail=document.getElementById('formEmail')
                let formElement=document.getElementById('formSubscribe')
                let closeBtn=document.getElementById('closeModalSubscribe')
                formElement.reset();
                formEmail.reset();
                closeBtn.click();
                if(response.data.data.status == 'success'){
                    this.alertSuccess();
                }else{
                    this.alertError();
                }
            })
            .catch((error)=>{
                document.getElementById('btnConfirmSub').disabled=false;
                console.log(error)
            });
    },
    openTab(e){
        var url=e.target.value;
        if(url != ''){
            window.open(url)
            document.getElementById('closeModalLogin').click()
        }
    },
    editQty(e,id){
        // if(e.target.value == '' || e.target.value < 1){
        //    e.target.value = 1;
        // }
        let item = {
            id:id,
            qty: e.target.value
        }
        this.updateQty(item)
        
    },
    editSampleQty(e,id){
        
        let item = {
            id:id,
            qty: e.target.value
        }
        this.updateSampleQty(item)
        
    },
    selectCode(){
        var country = this.countries.find((country) => country.name === this.userForm.country);

        this.userForm.phone_code = country.dial_code;
    },
    numberFormat(e){
        if(!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8 || e.keyCode == 39 || e.keyCode == 37 || e.keyCode == 9)) {
           e.preventDefault();
        }
    },
    nl2br (str) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    checkData(){
        const inputs = document.getElementsByClassName('offer-qty');
        for (const input of inputs) {
            if(input.value == '' || input.value < 1){
                input.focus()
                this.$swal(
                {
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: 'Please fill quantity',
                    showConfirmButton: false,
                    timer: 2500
                    }
                );
                return false;
            }
        }
        
        if((this.user.email == ''  || this.user.email == null) && (this.userForm.email == '' || this.userForm.email == null)){
            
            if((this.userForm.email == '' || this.userForm.email == null) && this.showCartContent == 0) {
                this.$swal(
                    {
                    toast: true,
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Please fill all information',
                    showConfirmButton: false,
                    timer: 2500
                    }
                );
            }
            this.setShowCartContent(0)
        }else{
            if(this.user.email){
                this.setShowCartContent(1)
                this.sendRequetsOffer();
            }else{
                if(validator.isEmail(this.userForm.email)){
                    if(this.userForm.full_name != '' && this.userForm.department != '' && this.userForm.company != '' && this.userForm.website != '' && this.userForm.country != '' && this.userForm.industry != '' && this.userForm.phone != '' && this.userForm.phone_code != '' ){
                        this.setUser(this.userForm)
                        this.setShowCartContent(1)
                        this.sendRequetsOffer();
                    }else{
                        this.$swal(
                        {
                            toast: true,
                            position: 'top-end',
                            icon: 'error',
                            title: 'Please fill all information',
                            showConfirmButton: false,
                            timer: 2500
                            }
                        );
                        this.setShowCartContent(0)
                    }
                }else{
                    this.$swal(
                        {
                        toast: true,
                        position: 'top-end',
                        icon: 'error',
                        title: 'Failed, Email is not Valid',
                        showConfirmButton: false,
                        timer: 2500
                        }
                    );
                    this.setShowCartContent(0)
                }
            }
            
        }
    },
    sendRequetsOffer(){
            let btn=document.getElementById('btnSendOffer');
            btn.disabled=true
            btn.innerHTML='<i class="fa fa-spinner fa-spin"></i> Sending Request';
            let formdata = new FormData();
            formdata.append("email",  this.user.email)
            formdata.append("name",  this.user.full_name);
            formdata.append("department",  this.user.department);
            formdata.append("company",  this.user.company);
            formdata.append("website",  this.user.website);
            formdata.append("phone",  this.user.phone);
            formdata.append("phone_code",  this.user.phone_code);
            formdata.append("country",  this.user.country);
            formdata.append("industry",  this.user.industry);
            formdata.append("products",  JSON.stringify(this.selectedProducts));
            let bodyContent =  formdata;
            let reqOptions = {
                url: this.apiUrl+"/webx/c_request_offer/",
                method: "POST",
                data: bodyContent,
            }      
            axios.request(reqOptions)
            .then((response) => {
                if(response.data.data.status == 'success'){
                    this.$swal(
                        {
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        title: 'Your request has been sent. You should hear back shortly',
                        showConfirmButton: false,
                        timer: 2500
                        }
                    );
                    this.clearSelectedProduct()
                }else{
                    this.alertError();
                }
                document.getElementById('closeModalOffer').click();
                btn.disabled=false
                btn.innerHTML='Submit';
            })
            .catch((error)=>{
                console.log(error)
                document.getElementById('closeModalOffer').click();
                btn.disabled=false
                btn.innerHTML='Submit';
            });
    },
    checkDataSample(){
        
        if((this.user.email == ''  || this.user.email == null) && (this.userForm.email == '' || this.userForm.email == null)){
            if((this.userForm.email == '' || this.userForm.email == null) && this.showSampleContent == 0) {
                this.$swal(
                    {
                        toast: true,
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Please fill all information',
                        showConfirmButton: false,
                        timer: 2500
                    }
                );
            }
            this.setShowSampleContent(0)
        }else{
            if(this.user.email){
                this.setShowSampleContent(1)
                this.sendSampleRequest();
            }else{
                if(validator.isEmail(this.userForm.email)){
                    if(this.userForm.full_name != '' && this.userForm.department != '' && this.userForm.company != '' && this.userForm.website != '' && this.userForm.country != '' && this.userForm.industry != '' && this.userForm.phone != '' && this.userForm.phone_code != '' ){
                        this.setUser(this.userForm)
                        this.setShowSampleContent(1)
                        this.sendSampleRequest();
                    }else{
                        this.$swal(
                        {
                            toast: true,
                            position: 'top-end',
                            icon: 'error',
                            title: 'Please fill all information',
                            showConfirmButton: false,
                            timer: 2500
                            }
                        );
                        this.setShowSampleContent(0)
                    }
                }else{
                    this.$swal(
                        {
                        toast: true,
                        position: 'top-end',
                        icon: 'error',
                        title: 'Failed, Email is not Valid',
                        showConfirmButton: false,
                        timer: 2500
                        }
                    );
                    this.setShowSampleContent(0)
                }
            }
            
        }
    },

    sendSampleRequest(){
        let btn=document.getElementById('btnSampleRequest');
        btn.disabled=true
        btn.innerHTML='<i class="fa fa-spinner fa-spin"></i> Sending Request';
        let formdata = new FormData();
        formdata.append("email",  this.user.email)
        formdata.append("name",  this.user.full_name);
        formdata.append("department",  this.user.department);
        formdata.append("company",  this.user.company);
        formdata.append("website",  this.user.website);
        formdata.append("phone",  this.user.phone);
        formdata.append("phone_code",  this.user.phone_code);
        formdata.append("country",  this.user.country);
        formdata.append("industry",  this.user.industry);
        formdata.append("courier_details",  this.courier);
        formdata.append("products",  JSON.stringify(this.selectedSamples));

        let bodyContent =  formdata;
        let reqOptions = {
            url: this.apiUrl+"/webx/c_request_sample/",
            method: "POST",
            data: bodyContent,
        }      
        axios.request(reqOptions)
        .then((response) => {
            if(response.data.data.status == 'success'){
                this.$swal(
                    {
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your request has been sent. You should hear back shortly',
                    showConfirmButton: false,
                    timer: 2500
                    }
                );
                this.clearSelectedSample()
            }else{
                this.alertError();
            }
            document.getElementById('closeModalSample').click();
            btn.disabled=false
            btn.innerHTML='Submit';
        })
        .catch((error)=>{
            console.log(error)
            document.getElementById('closeModalSample').click();
            btn.disabled=false
            btn.innerHTML='Submit';
        });
    },

    getPopupMedia() {
        let reqOptions = {
            url: this.apiUrl+'/webx/r_popup_media',
            method: "GET",
        }   
        axios.request(reqOptions)
            .then((response) => {
                if (response.data.data !== null && response.data.data !== undefined) {
                    this.popupMedia=response.data.data;
                    this.isMediaPopupFound = true;
                    this.showPopup();
                } else{
                    this.isMediaPopupFound=false;
                    localStorage.removeItem('popup');
                    localStorage.removeItem('popupExpiration');
                }
            })
            .catch((err) => {
                console.error(err) 
            });  
    },

    showPopup () {
        // check popup apakah pernah muncul di user atau tidak
        let popupShow = localStorage.getItem('popup');
        if (this.isMediaPopupFound && popupShow !== 'true') {
            // Show the popup
            document.getElementById('modalMediaTrigger').click();
        } else {
            // Check if the popup has expired
            const expirationTime = localStorage.getItem('popupExpiration');

            if (expirationTime) {
                const currentTime = new Date().getTime();

                if (currentTime >= parseInt(expirationTime, 10)) {
                    // The popup has expired; remove it from localStorage
                    localStorage.removeItem('popup');
                    localStorage.removeItem('popupExpiration');
                }
            }
        }
    },

    showPopupCountryLanguage() {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            // If language is saved, set the language and hide language selection
            this.selectedLanguage = savedLanguage;
            this.showLanguage = false;
        }
        
        // Check if both language and country are set in cookies
        const savedSettings = this.getCookie('settings');
        // let currentUrl = window.location.href;
        if (savedSettings) {
            const { language, country } = JSON.parse(savedSettings);
            // If both language and country are present in cookies, set them
            this.selectedLanguage = language;
            this.selectedCountry = country;
        } else {
            
            // if (currentUrl.includes('/sources')) {
            //     document.getElementById("btnSetCountryLang").click()
            // } else if(currentUrl.includes('/product-catalog')) {
            //     document.getElementById("btnSetCountryLang").click()
            // }
            document.getElementById("btnSetCountryLang").click()
        }
    },

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    },
    setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
    },

    saveSettings() {

        // Save language and country to cookies
        if(this.selectedCountry) {
            const settings = {
                country: this.selectedCountry,
                language: this.selectedLanguage ? this.selectedLanguage : 'EN',
            };
            this.setCookie('settings', JSON.stringify(settings), 365); // Set cookie for 30 days

            // Save language to local storage

            if (!localStorage.getItem('language')) {
                let selectedLang = this.selectedLanguage ? this.selectedLanguage : 'EN';
                localStorage.setItem('language', selectedLang);
            }

            if(this.selectedLanguage == 'EN' || this.selectedLanguage == ''){
                this.FlagIcon='united-kingdom.svg';
            }else if(this.selectedLanguage == 'ID'){
                this.FlagIcon='indonesia-flag.svg';
            }else if(this.selectedLanguage == 'CN'){
                this.FlagIcon='china-flag.svg';
            }else if(this.selectedLanguage == 'FR'){
                this.FlagIcon='france-flag.svg';
            }else if(this.selectedLanguage == 'SP'){
                this.FlagIcon='spain.svg';
            }else if(this.selectedLanguage == 'JP'){
                this.FlagIcon='japan-flag.svg';
            }else if(this.selectedLanguage == 'VN'){
                this.FlagIcon='vietnam-flag.svg';
            }else if(this.selectedLanguage == 'TR'){
                this.FlagIcon='turkey-flag.svg';
            }else if(this.selectedLanguage == 'RU'){
                this.FlagIcon='russia-flag.svg';
            }
            localStorage.setItem('FlagIcon',this.FlagIcon);            
            
            window.location.reload();
        } else {
            var element = document.getElementById("selectCountry");
            element.classList.add("is-invalid");
            element.classList.add("p-invalid");
        }
    },
    
    sortCountries() {
        this.countryList.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
            return -1;
            }
            if (nameA > nameB) {
            return 1;
            }
            return 0;
        });
    },

    // Handle manipulation dom for conficted select language
    showSelectLanguage(boolean) {
        this.showDropdownLanguage = boolean
    },

    openUrl(url) {
        document.getElementById("btnClosePopUpMedia").click()
        // Set the popup flag to true
        localStorage.setItem('popup', 'true');

        // Set the expiration time to 1 day from the current time
        const expirationTime = (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') 
        ? new Date().getTime() + 24 * 60 * 60 * 1000  // 1 day in milliseconds
        : new Date().getTime() + 60 * 1000;

        // Store the expiration time in localStorage
        localStorage.setItem('popupExpiration', expirationTime.toString());
        window.open(`${url}`, "_blank");
    },

    showCart(){
         this.setShowCartContent(1)
    },
    getClientIp() {
        let reqOptions = {
            url: this.apiUrl+'/webx/get_ip',
            method: "GET",
        }   
        axios.request(reqOptions)
            .then((response) => {
                localStorage.setItem('client_ip', response.data.data);
            })
            .catch((err) => {
                console.error(err) 
            });
    },
    getCountryInfo() {
        let reqOptions = {
            url: this.apiUrl+'/webx/ip_info',
            method: "GET",
        }
        axios.request(reqOptions)
            .then((response) => {
                this.selectedCountry = response.data;

                let country = this.selectedCountry;

                let savedLanguage = localStorage.getItem('language');

                //set time for one year
                const now = new Date();
                const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000);
                let expiration = expirationDate.getTime()

                localStorage.setItem('expired_date',expiration) //set expired date for local storage
                localStorage.setItem('country',country)

                if (country == 'Indonesia') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'ID';
                } else if (country == 'France') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'FR';
                } else if (country == 'China') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'CN';
                } else if (country == 'Spain') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'SP';
                } else if (country == 'Japan') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'JP';
                } else if (country == 'Vietnam') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'VN';
                } else if (country == 'Turkey' || country == 'Türkiye') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'TR';
                } else if (country == 'Russia') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'RU';
                } else if (country == 'United Arab Emirates') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'SA';
                } else if (country == 'South Korea') {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'KR';
                } else {
                    this.selectedLanguage = savedLanguage ? savedLanguage : 'EN';
                }

                if (!localStorage.getItem('language')) {
                    let selectedLang = this.selectedLanguage ? this.selectedLanguage : 'EN';
                    localStorage.setItem('language', selectedLang);
                }

                if(this.selectedLanguage == 'EN' || this.selectedLanguage == ''){
                    this.FlagIcon='united-kingdom.svg';
                }else if(this.selectedLanguage == 'ID'){
                    this.FlagIcon='indonesia-flag.svg';
                }else if(this.selectedLanguage == 'CN'){
                    this.FlagIcon='china-flag.svg';
                }else if(this.selectedLanguage == 'FR'){
                    this.FlagIcon='france-flag.svg';
                }else if(this.selectedLanguage == 'SP'){
                    this.FlagIcon='spain.svg';
                }else if(this.selectedLanguage == 'JP'){
                    this.FlagIcon='japan-flag.svg';
                }else if(this.selectedLanguage == 'VN'){
                    this.FlagIcon='vietnam-flag.svg';
                }else if(this.selectedLanguage == 'TR'){
                    this.FlagIcon='turkey-flag.svg';
                }else if(this.selectedLanguage == 'RU'){
                    this.FlagIcon='russia-flag.svg';
                }else if(this.selectedLanguage == 'SA'){
                    this.FlagIcon='sa.svg';
                }else if(this.selectedLanguage == 'KR'){
                    this.FlagIcon='kr.svg';
                }
                localStorage.setItem('FlagIcon',this.FlagIcon);            
            })
            .catch((err) => {
                console.error(err) 
            });
    },

    getCountryCrossref() {
        let reqOptions = {
            url: this.apiUrl+'/webx/get_country_crossref',
            method: "GET",
        }   
        axios.request(reqOptions)
            .then((response) => {
                
                if (response.data.data !== null && response.data.data !== undefined) {
                    this.countryList = Object.keys(response.data.data).map(key => ({
                        name: response.data.data[key].name,
                        emoji: response.data.data[key].emoji
                    }));
                    
                    this.showPopupCountryLanguage(); 
                } else {
                    console.log(response);
                }
            })
            .catch((err) => {
                console.error(err) 
            });
    },
     ...mapActions({
        updateQty:"products/updateQty",
        unselectProduct:"products/unselectProduct",
        setShowCartContent:"products/setShowCartContent",
        clearSelectedProduct:"products/clearSelectedProduct",
        setUser:"products/setUser",
        unSelectSample:"products/unSelectSample",
        setShowSampleContent:"products/setShowSampleContent",
        clearSelectedSample:"products/clearSelectedSample",
        updateSampleQty:"products/updateSampleQty",
    })
  },
  computed:{
   ...mapGetters({
        selectedProducts:"products/selectedProducts",
        showCartContent:"products/showCartContent",
        user:"products/user",
        selectedSamples:"products/selectedSamples",
        showSampleContent:"products/showSampleContent",
    }),
    contentBlog() {
        if (this.popupMedia.content) {
            // Remove HTML tags from content
            let plainTextContent = this.popupMedia.content.replace(/<\/?[^>]+(>|$)/g, '');
            // plainTextContent = this.popupMedia.content.replace(/&nbsp;/g, ' ');
            if (plainTextContent.length > 90) {
                return plainTextContent.substring(0, 90) + '...';
            } else {
                return plainTextContent;
            }
        }
        return this.popupMedia.content;
    },
    // openModalCountryLanguage() {
    //     this.
    // }
  },

}
</script>
<style scoped>
.modal-sm{
    max-width: 350px !important;
}
h5.modal-title{
    font-size: 16px;
}
.form-control[readonly] {
     background-color: transparent;
    opacity: 1;
}
.btn-xs:first-child{
    margin-right: 10px;
}
.btn-xs{
    padding: 5px;
    font-size: 12px;
    width: 45%;
    min-width: unset;
    border-color: #d2d2d2;
}
.qty{
    width: 80px;
    height: unset;
    text-align: center;

}
.shop_table{
    font-size: 14px;
}
.shop_table td{
    padding: 0px 5px;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.25s ease-out;
}

.slide-fade-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.content-offer{
    height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}
.btn-offer{
    padding: 10px !important;
    height: unset;
    min-width: 120px;
}
.btn-offer:disabled {
  color: #d2d2d2 !important;
  border-color: #d2d2d2;
  cursor: not-allowed;
}
.btn-clear-selection{
    width: auto;
    min-width: unset;
    padding: 5px 10px;
    font-size: 10px;
    background-color: #2d3545;
    color: #fff;
    border-color: #2d3545;
}
.btn-clear-selection:hover{
    color: #fff;
}
.sample-qty{
    width: 70px;
}
.modal-sample{
    max-width: 500px;
}

.modal-sm-popupmedia {
    max-width: 650px !important;
}

.modal-sm-setlanguage {
    max-width: 275px !important;
}
.p-dropdown {
  z-index: 9999 !important; 
}
.p-dropdown-panel {
    z-index: 9999 !important; 
}

.p-inputtext {
    box-shadow: none !important;
}
.p-inputtext:enabled:focus {
    box-shadow: none !important;
}

.p-dropdown-filter .p-inputtext .p-component{
    box-shadow: none !important;
}
</style>