import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import MapsView from '../views/MapsView.vue'
import BotanicalSources from '../views/BotanicalSources.vue'
import ProductCatalog from '../views/ProductCatalog.vue'
import DistributionNetwork from '../views/DistributionNetwork.vue'
import ContactView from '../views/ContactView.vue'
import SustainabilityView from '../views/SustainabilityView.vue'
import ProductDetail from '../views/ProductDetail.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import EmailPrivacy from '../views/EmailPrivacy.vue'
// import PricelistView from '../views/PricelistView.vue'
import MarketReport from '../views/MarketReport.vue'
import MediaView from '../views/MediaView.vue'
import PostView from '../views/PostView.vue'
import NilampediaView from '../views/NilampediaView.vue'
import CengkehpediaView from '../views/CengkehpediaView.vue'
import LeadershipView from '../views/LeadershipView.vue'
import CareerView from '../views/CareerView.vue'

//botanical sources
import PatchouliPage from '../views/botanical-sources/PatchouliPage.vue'
import ClovePage from '../views/botanical-sources/ClovePage.vue'
import CitronellaPage from '../views/botanical-sources/CitronellaPage.vue'
import NutmegPage from '../views/botanical-sources/NutmegPage.vue'
import GurjunBalsam from '../views/botanical-sources/GurjunBalsam.vue'
import GurjunBalsamCopaene from '../views/botanical-sources/GurjunBalsamCopaene.vue'
import KaffirLime from '../views/botanical-sources/KaffirLime.vue'
import VetiverPage from '../views/botanical-sources/VetiverPage.vue'
import CanangaPage from '../views/botanical-sources/CanangaPage.vue'
import MassoiaBark from '../views/botanical-sources/MassoiaBark.vue'
import CajeputPage from '../views/botanical-sources/CajeputPage.vue'
import TobaccoPage from '../views/botanical-sources/TobaccoPage.vue'
import GingerPage from '../views/botanical-sources/GingerPage.vue'
import RedGingerPage from '../views/botanical-sources/RedGingerPage.vue'
import CocoaPage from '../views/botanical-sources/CocoaPage.vue'
import CoffeeRobusta from '../views/botanical-sources/CoffeeRobusta.vue'
import CoffeeArabica from '../views/botanical-sources/CoffeeArabica.vue'
import SumatraBenzoin from '../views/botanical-sources/SumatraBenzoin.vue'

//page not found
import PageNotFound from '../views/404.vue'

const routes = [
  {
    path: '/',
    name: 'landing-page',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/leadership',
    name: 'leadership',
    component: LeadershipView
  },
  {
    path: '/maps',
    name: 'maps',
    component: MapsView
  },
  {
    path: '/maps/:source',
    name: 'specific-maps',
    component: MapsView
  },
  {
    path: '/distribution-network',
    name: 'distribution-network',
    component: DistributionNetwork
  },
  {
    path: '/sources',
    name: 'sources',
    component: BotanicalSources
  },
  {
    path: '/product-catalog',
    name: 'product-catalog',
    component: ProductCatalog
  },
  {
    path: '/product/:category',
    name: 'product-by-category',
    component: ProductCatalog
  },
  {
    path: '/product-detail/:slug',
    name: 'product-detail',
    component: ProductDetail
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/sustainability',
    name: 'sustainability',
    component: SustainabilityView
  },
  {
    path: '/nilampedia',
    name: 'nilampedia',
    component: NilampediaView
  },
  {
    path: '/cengkehpedia',
    name: 'cengkehpedia',
    component: CengkehpediaView
  },
  {
    path: '/media',
    name: 'media',
    component: MediaView
  },
  {
    path: '/post/:slug',
    name: 'post-detail',
    component: PostView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/email-privacy',
    name: 'email-privacy',
    component: EmailPrivacy
  },
  {
    path: '/career',
    name: 'career',
    component: CareerView
  },
  {
    path: '/career/:job',
    name: 'job-detail',
    component: CareerView
  },
  {
    path: '/patchouli-oils-indonesia',
    name: 'patchouli',
    component: PatchouliPage
  },
  {
    path: '/clove-oils-and-eugenol-derivatives',
    name: 'clove',
    component: ClovePage
  },
  {
    path: '/citronella-java-oil-and-citronella-fractions',
    name: 'citronella',
    component: CitronellaPage
  },
  {
    path: '/nutmeg-oil-java-and-mace-oil',
    name: 'nutmeg',
    component: NutmegPage
  },
  {
    path: '/gurjun-balsam-gurjunene',
    name: 'gurjun-balsam',
    component: GurjunBalsam
  },
  {
    path: '/gurjun-balsam-copaene',
    name: 'gurjun-balsam-copaene',
    component: GurjunBalsamCopaene
  },
  {
    path: '/kaffir-lime',
    name: 'kaffir-lime',
    component: KaffirLime
  },
  {
    path: '/vetiver-oil-java-indonesia',
    name: 'vetiver',
    component: VetiverPage
  },
  {
    path: '/cananga-oil',
    name: 'cananga',
    component: CanangaPage
  },
  {
    path: '/massoia-bark',
    name: 'massoia-bark',
    component: MassoiaBark
  },
  {
    path: '/cajeput-oil-indonesia',
    name: 'cajeput',
    component: CajeputPage
  },
  {
    path: '/tobacco-extract',
    name: 'tobacco',
    component: TobaccoPage
  },
  {
    path: '/fresh-ginger-oil',
    name: 'ginger',
    component: GingerPage
  },
  {
    path: '/red-ginger-oil',
    name: 'red-ginger',
    component: RedGingerPage
  },
  {
    path: '/cocoa-extract',
    name: 'cocoa',
    component: CocoaPage
  },
  {
    path: '/coffee-robusta',
    name: 'coffee-robusta',
    component: CoffeeRobusta
  },
  {
    path: '/coffee-arabica',
    name: 'coffee-arabica',
    component: CoffeeArabica
  },
  {
    path: '/benzoin-sumatra',
    name: 'benzoin-sumatra',
    component: SumatraBenzoin
  },
  {
    path: '/market-report',
    name: 'market-report',
    component: MarketReport
  },
  // {
  //   path: '/pricelist',
  //   name: 'pricelist',
  //   component: PricelistView
  // },
  

  //replace url lama
  {
    path: '/about.php',
    component: AboutView
  },
  {
    path: '/distribution_network.php',
    component: DistributionNetwork
  },
  {
    path: '/botanical_sources.php',
    component: BotanicalSources
  },
  {
    path: '/product_catalog.php',
    component: ProductCatalog
  },
  {
    path: '/sustainability.php',
    component: SustainabilityView
  },
  {
    path: '/contact.php',
    component: ContactView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
