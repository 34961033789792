<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>GURJUN BALSAM</h3>
                <h5>Dipterocarpus turbinatus</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/gurjun_int.jpg'" class="img-source p-4" alt="Gurjun Balsam Indonesia">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">Introduction</h4>
                        <p>The crude oil collected from gurjun tree (Popularly known as : KERUING TIMBER TREE Or Kayu Lagan) is naturally dark in appearance, hence known as gurjun dark. The crude oil can also  be re-distilled to obtain gurjun light</p>
                        <p>Gurjun is primarily cultivated in the southeast asia region (Malay Peninsula, Sumatra, Borneo, the Philippines, Java, Bali, Lombok and Sumbawa). It is, however, important to note that the oils obtained from Gurjun trees in the the above listed countries have different constituents. The Indonesian variety of Gurjun oil has Alpha copaene (Min 40%) as it’s main component. The other quality from Malaysia/Vietnam/Cambodia is different as the main constituent is Gurjunene (Min 40%).
                        </p>
                    </div>
                    <div class="col-md-12 mt-5 mb-5">
                        <div class="row mt-5">
                            <div class="col-md-12">
                                <div class="mt-2">
                                    <img :src="imageUrl+'new_website/images/botanical-source/gurjun_m.jpg'" class="w-100" alt="Gurjun Balsam Indonesia">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <FaqElement source="fbf5b41d-14cd-42e2-9753-26ccc7f07509"/>
        <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Gurjun Balsam Copaene Product List</h4>
                    </div>
                    <ProductList source="fbf5b41d-14cd-42e2-9753-26ccc7f07509"/>
                </div>
            </div>
        </div>
        <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'GurjunBalsam',
   data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
    }
  },
  components:{
        ProductList,
        FaqElement
    },
  mounted() {
    let oldScript=document.getElementById('mainJS');
    if(oldScript){
      oldScript.remove();
    }
    let mainJS = document.createElement('script')
    mainJS.setAttribute('id', 'mainJS')
    mainJS.setAttribute('src', 'assets/js/main.js')
    document.body.appendChild(mainJS)

    let oldPopover= document.getElementById('popoverJS');
    if(oldPopover){
        oldPopover.remove()
    }
    let popoverJS = document.createElement('script')
    popoverJS.setAttribute('id', 'popoverJS')
    popoverJS.setAttribute('src', 'assets/js/reinit.js')
    document.body.appendChild(popoverJS)

    document.title = 'Gurjun Balsam Copaene - Van Aroma';
  }
}
</script>
