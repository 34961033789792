<template>
    <div class="col-md-10">
        <div class="text-center mt-5 p-5" v-if="getTry >= 5">
            <p>Failed to get request</p>
            <a class="link-reload" href="https://vanaroma.com/product-catalog">Reload Page</a>
        </div>
        <div class="table-container">
          <table class="table" id="productList" v-if="!emptyResults">
              <thead>
                <tr>
                  <th style="cursor: pointer;" @click="sortTable('productList',0)">Product Code <i class="fas fa-sort float-end"></i></th>
                  <th style="cursor: pointer;" @click="sortTable('productList',1)">Product Name <i class="fas fa-sort float-end"></i></th>
                  <th style="cursor: pointer;" @click="sortTable('productList',2)">Category <i class="fas fa-sort float-end"></i></th>
                  <th style="cursor: pointer;" @click="sortTable('productList',3)">Olfactive Description <i class="fas fa-sort float-end"></i></th>
                  <th style="cursor: pointer;" @click="sortTable('productList',4)">Product Form <i class="fas fa-sort float-end"></i></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in dataProduct" :key="product.product_ipc">
                    <td>{{ product.product_ipc }}</td>
                    <td>{{ product.product_name }}</td>
                    <td style="text-transform: capitalize;">{{ product.product_category_name.toLowerCase() }}</td>
                    <td>{{ product.olfactory_profile }}</td>
                    <td>{{ product.product_form }}</td>
                    <td style="text-align:right">
                      <router-link class="btn-detail d-none d-md-inline" :to="'/product-detail/'+product.slug">Detail</router-link>
                      <router-link class="btn-detail d-block d-md-none" :to="'/product-detail/'+product.slug"><i class="fa fa-list"></i></router-link>
                    </td>
                </tr>
              </tbody>
          </table>
        </div>
    </div>
</template>

<style scoped>
.table-container {
    overflow-x: auto;
    max-width: 100%;
}
.table td {
    font-size: 16px;
}

@media (max-width: 767px) {
    .table td {
        font-size: 12px;
    }
}
</style>

<script>
import axios from "axios";
export default {
  name: 'ProductList',
  props: ["source"],
  data() {
    return {
        emptyResults:false,
        getTry:0,
        apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
        dataProduct:[]
    };
  },
  watch: {
        dataProduct:function(val){
            if(Object.keys(val).length === 0 && this.getTry < 5){ //max attemp 5
                setTimeout(() => {
                    this.getProducts()
                    this.getTry++
                    console.log(this.getTry)
                }, 1000);
            }
        },
  },
  methods:{
    getProducts(){
      let formdata = new FormData();
      formdata.append("source", this.source);
      let bodyContent =  formdata;
      let reqOptions = {
          url: this.apiUrl+'/webx/r_product_by_source',
          method: "POST",
          data: bodyContent,
      }      
      axios.request(reqOptions)
          .then((response) => {
            if(response.data.data == undefined){
                console.log('retrying to get data')
                if(this.emptyResults == false && this.getTry < 5){
                  console.log('retrying')
                    setTimeout(() => {
                        this.getProducts()
                        this.getTry++
                    }, 1000);
                }
            }else{
              if(Object.keys(response.data.data).length !== 0){
                this.dataProduct=response.data.data
              }else{
                this.emptyResults=true
              }
              // console.log(response)
            }
          })
          .catch((error) => {
            console.log(error)
            this.dataProduct=[]
            this.emptyResults=true
          }
      );
    },
    sortTable(el, n) {
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      table = document.getElementById(el);
      switching = true;
      dir = "asc";
      while (switching) {
        switching = false;
        rows = table.rows;
        for (i = 1; i < (rows.length - 1); i++) {
          shouldSwitch = false;
          x = rows[i].getElementsByTagName("TD")[n];
          y = rows[i + 1].getElementsByTagName("TD")[n];
          if (dir == "asc") {
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          } else if (dir == "desc") {
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          switchcount++;
        } else {
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }
    }
  },
  mounted(){
    this.getProducts()
  }
  
}
</script>
<style scoped>
  .btn-detail{
     font-size: 12px !important;
     padding: 5px 15px !important;
     border: 1px solid #000;
  }
  .table th:first-child{
    width: 150px;
  }
  @media (max-width: 500px) {
    .table th:first-child{
      width: 120px;
    }
  }
</style>