<template>
  <!--Location-->
  <section class="header-section">
    <div class="container">
      <div class="row py-3">
        <div class="col-md-12">
          <h1>Email's Privacy</h1>
        </div>
      </div>
    </div>
  </section>
  <section class="ls s-py-90">
    <div class="container mb-5">
      <div class="row mb-5">
        <div class="col-md-12 mb-5">
          <p>
            The e-mail message and any attachments, is for the exclusive use of the intended recipient or entity to which it is addressed and may contain confidential, proprietary and/or legally privileged information and shall not attach any liability on the originator or Van Aroma /its Subsidiaries/its Group Companies. Kindly note that any distribution, copying or use of this communication or the information in this e-mail message is strictly prohibited. If you have erroneously received this message, please notify the sender immediately and delete this message from your system. Van Aroma /its Subsidiaries/its Group Companies or the Originator neither accept responsibility for any loss or damage arising from the use of this message or attachments nor guarantees the integrity of this communication, as e-mail transmissions are not secure and may suffer errors, viruses, delay, interception and amendment. Before opening any attachments please recheck them for viruses and defects
          </p>
        </div>
      </div>
    </div>
  </section>
  <!--Posts End-->
</template>
<script>
export default {
  name: "EmailPrivacy",
  mounted() {
    let oldScript = document.getElementById("mainJS");
    if (oldScript) {
      oldScript.remove();
    }
    let mainJS = document.createElement("script");
    mainJS.setAttribute("id", "mainJS");
    mainJS.setAttribute("src", "/assets/js/main.js");
    document.body.appendChild(mainJS);
    document.title = "Email's Privacy - Van Aroma";
  },
  methods: {},
};
</script>
<style src="@/assets/css/about.css" scoped></style>
<style scoped>
h1 {
  font-size: 30px;
}
h2 {
  font-size: 25px;
}
h3 {
  font-size: 20px;
}
h4{
  font-size: 16px;
}
h2, h3, h4, h5, h6{
  margin-top: 2.5rem;
}
h2.title{
  padding: 20px 0px;
  border-bottom: 1px solid #eaebec;
}
.header-section {
  background-color: #2d3545;
  padding-top: 30px;
  padding-bottom: 20px;
  color: #fff;
}
@media (max-width: 768px) {
}
</style>
