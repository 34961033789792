import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import drag from "v-drag"
import { VueReCaptcha } from 'vue-recaptcha-v3'
import PrimeVue from 'primevue/config';
createApp(App).
use(router)
.use(store)
.use(VueSweetalert2)
.use(drag)
.use(PrimeVue, { 
    inputStyle: "filled",
    ripple: true,
    zIndex: {
        modal: -1,        //dialog, sidebar
        overlay: 9999,      //dropdown, overlaypanel
        // menu: 1000,         //overlay menus
        // tooltip: 1100       //tooltip
    }
})
.use(VueReCaptcha, { siteKey: '6LdFHusZAAAAAPY6fhWn5VVcMD51JybKegrgBxer' })
.mount('#app')
