<template>
  <div>
    <header class="page_header ls justify-nav-center ">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-xl-2 col-lg-3 col-11">
					<router-link to="/" class="logo">
						<img src="../assets/va-logo.png" alt="img">
					</router-link>
				</div>
				<div class="col-xl-8 col-1 text-sm-center">
					<!-- main nav start -->
					<nav class="top-nav">
						<ul class="nav sf-menu">
							<li class="nav-item" :class="{ active: currentRouteName==='home' }">
							<router-link to="/home" class="router-link">
								Home
							</router-link>
							</li>
							
							<li class="nav-item" :class="{ active: currentRouteName==='about' || currentRouteName==='distribution-network' || currentRouteName==='maps' || currentRouteName==='leadership' || currentRouteName==='career' || currentRouteName==='job-detail' }">
								<a class="nav-link" href="#" @click.prevent>Company</a>
								<ul class="submenu">
									<li class="nav-item">
									<router-link to="/about" class="nav-link router-link">About Us</router-link>
									</li>
									<li class="nav-item">
										<router-link to="/leadership" class="nav-link router-link">Our Leadership</router-link>
									</li>
									<li class="nav-item">
										<router-link class="nav-link router-link" to="/maps">Maps</router-link>
									</li>
									<li class="nav-item">
										<router-link to="/distribution-network" class="nav-link router-link" >Distribution Network</router-link>
									</li>
									<li class="nav-item">
										<router-link to="/career" class="nav-link router-link">Career</router-link>
									</li>
								</ul>
							</li>
							<li class="nav-item" :class="{ active: currentRouteName==='nilampedia' || currentRouteName==='cengkehpedia' }">
								<a class="nav-link" href="#" @click.prevent>Sustainability</a>
								<ul class="submenu">
									<li class="nav-item">
									<router-link to="/nilampedia" class="nav-link router-link">Nilampedia</router-link>
									</li>
									<li class="nav-item">
									<router-link to="/cengkehpedia" class="nav-link router-link">Cengkehpedia</router-link>
									</li>
								</ul>
							</li>
							<li class="nav-item" :class="{ active: currentRouteName === 'product-catalog' || currentRouteName === 'sources' || currentRouteName === 'product-by-category' || currentRouteName === 'product-detail' }">
								<a class="nav-link" href="#" @click.prevent >Products</a>
								<ul class="submenu">
									<li class="nav-item">
										<router-link to="/sources" class="nav-link router-link" >Botanical Sources</router-link>
									</li>
									<li class="nav-item">
										<router-link to="/product-catalog" class="nav-link router-link" >Product Catalog</router-link>
									</li>
								</ul>
							</li>
							<!-- <li class="nav-item" :class="{ active: currentRouteName==='sustainability' }">
								<router-link to="/sustainability" class="router-link">Sustainability</router-link>
							</li> -->
							<li class="nav-item" :class="{ active: currentRouteName==='contact' }">
								<router-link to="/contact" class="router-link">Contact</router-link>
							</li>
							<li class="nav-item" :class="{ active: currentRouteName==='media' || currentRouteName==='post-detail' }">
								<router-link to="/media" class="router-link">Media</router-link>
							</li>
						</ul>


					</nav>
					<!-- eof main nav -->
				</div>
				<div class="col-xl-2 col-lg-7 text-end d-none d-lg-block">

					<ul class="top-includes">
						<li class="search-bar">
							<div id="search-wrap" class="search-wrap">
								<form action="/product-catalog" autocomplete="on">
									<input name="search" type="text" placeholder="search.." v-model="query" v-debounce:1000ms="search" @blur="doneSearch()">
									<span class="search-icon"><i class="fa fa-search"></i></span>
								</form>
							</div>
							<div class="search-suggest">
								<ul id="searchBarDesktop">
									<li v-if="isTyping" class="text-center loading"><img src="@/assets/img/loading.gif" ></li>
									<li v-if="emptyResults" class="text-center">No results</li>
									<li v-for="result in searchResults" :key="result.title" @click="clickResult(result.url)">
										<div class="row">
											<div class="col-3">
												<div class="thumbnail">
													<img :src="result.thumbnail">
												</div>
											</div>
											<div class="col-9">
												<p class="title">{{ result.title }}</p>
												<p class="subtitle">{{ result.subtitle }}</p>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<a href="#" data-bs-toggle="modal" data-bs-target="#modalLogin"><i class="fa fa-user"></i></a>
						</li>
						<li>
							<a href="#" @click="setShowCartContent(1)" class="cart-icon" data-bs-toggle="modal" data-bs-target="#modalOffer">
								<i class="fa fa-shopping-cart" aria-hidden="true" id="cartIcon"></i>
								<span class="cart-label" id="totalCart">{{ (totalCart > 0) ? totalCart : '' }}</span>
							</a>
							<a href="#" @click="setShowSampleContent(1)" class="cart-icon" data-bs-toggle="modal" data-bs-target="#modalSample">
								<i class="fa-solid fa-bottle-droplet" id="sampleIcon"></i>
								<span class="cart-label" id="totalSample">{{ (totalSample > 0) ? totalSample : '' }}</span>
							</a>
						</li>
					</ul>

				</div>
			</div>
		</div>
		<!-- header toggler -->
		<div class="d-md-none cart-icon mobile">
			<a href="#" @click="setShowCartContent(1)" data-bs-toggle="modal" data-bs-target="#modalOffer">
				<i class="fa fa-shopping-cart" aria-hidden="true"></i>
				<span class="cart-label" id="totalCart">{{ (totalCart > 0) ? totalCart : '' }}</span>
			</a>
			<a href="#" @click="setShowSampleContent(1)" data-bs-toggle="modal" data-bs-target="#modalSample">
				<i class="fa-solid fa-bottle-droplet"></i>
				<span class="cart-label" id="totalSample">{{ (totalSample > 0) ? totalSample : '' }}</span>
			</a>
		</div>
		
		<span class="toggle_menu"><span></span></span>
	</header>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters,mapActions } from "vuex"
import { vue3Debounce } from 'vue-debounce'
export default {
	name: 'NavbarSection',
	directives: {
		debounce: vue3Debounce({ lock: true })
	},
	data() {
		return {
			apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
			searchResults:[],
			isTyping:false,
			emptyResults:false,
			query:'',
			currentCountry : localStorage.getItem('country') ? localStorage.getItem('country') : '',
			client_ip : localStorage.getItem('client_ip') ? localStorage.getItem('client_ip') : ''
		}
	},
	methods:{
		search(){
			if(this.query){
				if(this.query.length > 3){
					this.isTyping=true;
					let formdata = new FormData();
					formdata.append("query", this.query);
					let bodyContent =  formdata;
					let reqOptions = {
						url: this.apiUrl+'/webx/search',
						method: "POST",
						data: bodyContent,
					}      
					axios.request(reqOptions)
						.then((response) => {
							if(Object.keys(response.data.data).length !== 0){
								this.searchResults=response.data.data
								this.isTyping=false;
								this.emptyResults=false
							}else{
								this.searchResults=[];
								this.emptyResults=true
								this.isTyping=false;
							}
							// console.log(response)
						})
						.catch((error) => {
							console.log(error)
							this.searchResults=[]
							this.emptyResults=true
							this.isTyping=false;
						}
					);
				}
			}else{
				this.isTyping=false;
				this.emptyResults=false
				this.searchResults=[];
			}
		},
		doneSearch(){
			this.query='';
		},
		clickResult(url){
			this.$router.push(url)
			this.searchResults=[];
		},
		...mapActions({
			setShowCartContent:"products/setShowCartContent",
			setShowSampleContent:"products/setShowSampleContent",
		})
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		},
		...mapGetters({
            selectedProducts:"products/selectedProducts",
			selectedSamples:"products/selectedSamples",
        }),

		totalCart(){
			return this.selectedProducts.length
		},
		totalSample(){
			return this.selectedSamples.length
		},
	},
	mounted() {
		let navbarJS = document.createElement('script')
		navbarJS.setAttribute('src', '/assets/js/navbar.js')
		document.head.appendChild(navbarJS)
		var self = this
		window.addEventListener('click', function(e){   
			if (!document.getElementById('searchBarDesktop').contains(e.target)){
				self.searchResults=[];
			} 
		});
	}
}
</script>
<style scoped>
.top-includes li a{
	letter-spacing: 0.05em;
	color: #686e7b;
	font-size: 16px;
}
.top-includes li a i{
	margin-right: 5px;
}
.container-fluid{
	padding-right: 1.75rem;
	padding-left: 1.75rem;
}
.search-bar{
	position: relative;
}
#search-wrap {
  position: relative;
  padding: 0;
  margin-top: -20px;
  margin-right: 20px;
}

.search-bar input[type="text"] {
  display: inline-block;
  border: none;
  outline: none;
  color: #555;
  padding: 3px;
  padding-right: 60px;
  width: 0px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  z-index: 3;
  height: 30px;
  transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
  /* transition-timing-function: ease-out; */
  cursor: pointer;
}

.search-bar input[type="text"]:focus:hover {
  border-bottom: 1px solid #BBB;
}

.search-bar input[type="text"]:focus {
  width: 200px;
  z-index: 1;
  border-bottom: 1px solid #BBB;
  cursor: text;
}
.search-icon {
  display: inline-block;
  float: right;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 1;
  color: #696e7c;
  cursor: pointer;
  transition: opacity .4s ease;
}

.search-icon:hover {
  opacity: 0.5;
}
.search-suggest{
	position: absolute;
	right: 0;
	top: 15px;
	width: 300px;
	right: 20px;
}
.search-suggest ul:empty {
   display: none;
}
.search-suggest ul{
	max-height: 250px;
	overflow-y: auto;
	background-color: #f8f9f9;
	padding: 10px;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	list-style: none !important;
}
.search-suggest ul::-webkit-scrollbar {
  display: none;
}

.search-suggest ul li{
	background-color: #fff;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 12px;
	padding: 10px;
	text-align: left;
	width: 100%;
	line-height: 18px;
	word-wrap: break-word;      /* IE 5.5-7 */
	white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
	white-space: pre-wrap;      /* current browsers */
}
.search-suggest ul li .thumbnail img{
	width: 100%;
	border-radius: 5px;
}
.search-suggest ul li .title{
	margin: 0px;
	font-size: 13px;
	font-weight: bold;
}
.cart-icon{
	margin-left: 10px;
	position: relative !important;
}
.cart-label{
	display: block;
    background-color: #ed1f1f;
    color: #fff;
    position: absolute;
    top: -5px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    left: -10px;
    line-height: 20px;
	transition: all 0.5s ease-in-out;
}
.cart-label:empty{
	display: none;
}
.cart-icon.mobile{
	position: absolute !important;
    display: inline;
    top: 16px;
    right: 60px;
}
.cart-icon.mobile a{
	margin:0px 10px;
	position: relative;
}

</style>