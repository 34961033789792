<template>
  <div class="mt-md-5 dont-copy">
     <section class="pb-150 mb-5">
        <div class="text-center" v-if="isLoading">
             <div class="loading mt-5">
                  <img src="@/assets/img/loading.gif" >
              </div>
        </div>
        <div class="text-center mt-5 p-5" v-if="getTry >= 5">
            <p>Failed to get request</p>
            <a class="link-reload" href="https://vanaroma.com/product-catalog">Reload Page</a>
        </div>
        <div class="container" v-if="emptyResults">
            <div class="row">
              <div class="col-md-12">
                <img :src="imageUrl+'new_website/images/search.png'" class="not-found-small-icon mt-5">
                <h4 class="text-center text-dark-grey">Ups!... no results found</h4>
              </div>
            </div>
        </div>
        <div v-if="Object.keys(product).length !== 0">
           <div class="container">
            <!-- mobile element -->
            <div class="row mt-5 mb-md-5 product-title-section d-md-none">
              <div class="col-lg-5 col-3">
                  <div class="img-product-box product-detail">
                      <img v-if="product.REACH_registration_no != ''" :src="imageUrl+'img/imageproduct/reach.png'" class="reach-icon">
                      <img v-if="!product.product_image" :src="imageUrl+'img/imageproduct/nope.jpg'" class="product-img">
                      <div v-else class="owl-carousel owl-loaded owl-drag owl-theme"  data-draggable="true" :data-dots="(product.product_image[1]) ? 'true' : 'false'" data-nav="false" data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                          <div v-for="img in product.product_image" :key="img.recid_product_image">
                                <img class="owl-lazy product-img" :data-src="imageUrl+'img/imageproduct/'+img.file_name" :src="imageUrl+'img/imageproduct/'+img.file_name">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-9 d-block d-md-none" style="position: relative;">
                <div class="product-title-mobile">
                  <h4>{{ product.product_name }}</h4>
                  <i v-text="'ex-'+capitalize(product.botanical_name.toLowerCase())"></i>
                </div>
              </div>
              <div class="col-12 p-0 d-md-none">
                <hr class="my-2">
              </div>
            </div>
            <!-- end mobile element -->

            <div class="row mt-md-5 mb-md-5">
              <div class="col-md-12 text-md-center d-none d-md-block">
                <ul class="breadcrumb">
                  <li><router-link to="/product-catalog">Products</router-link></li>
                  <li class="product-title">{{ product.product_name }}</li>
                </ul>
              </div>
              <div class="col-lg-5 d-none d-md-block">
                <div class="img-product-box product-detail">
                      <img v-if="product.REACH_registration_no != ''" :src="imageUrl+'img/imageproduct/reach.png'" class="reach-icon">
                      <img v-if="!product.product_image" :src="imageUrl+'img/imageproduct/nope.jpg'" class="product-img">
                      <div v-else class="owl-carousel owl-loaded owl-drag owl-theme"  data-draggable="true" :data-dots="(product.product_image[1]) ? 'true' : 'false'" data-nav="false" data-loop="true" data-autoplay="true" data-margin="80" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                          <div v-for="img in product.product_image" :key="img.recid_product_image">
                                <img class="owl-lazy product-img" :data-src="imageUrl+'img/imageproduct/'+img.file_name" :src="imageUrl+'img/imageproduct/'+img.file_name">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-7 px-md-5">
                  <table class="table table-info mt-md-5 mx-md-5">
                    <tr>
                      <td style="width:200px">Product Code</td>
                      <td>{{ product.product_ipc }}</td>
                    </tr>
                    <tr>
                      <td style="width:200px">Product Name</td>
                      <td>{{ product.product_name }}</td>
                    </tr>
                    <tr>
                      <td>Product Category</td>
                      <td>{{ product.product_category_name }}</td>
                    </tr>
                    <tr>
                      <td>Product Sources</td>
                      <td>{{ product.product_source_name }}</td>
                    </tr>
                    <tr>
                      <td>Origin Country</td>
                      <td>{{ product.origin_country }}</td>
                    </tr>
                    <tr v-if="product.olfactory_profile">
                      <td>Olfactory Profile</td>
                      <td>{{ product.olfactory_profile }}</td>
                    </tr>
                    <tr >
                      <td>Manufactured by</td>
                      <td>PT Van Aroma, Indonesia</td>
                    </tr>
                    <tr v-if="product.fragrance_note != ''">
                      <td>Fragrance Note</td>
                      <td>{{ product.fragrance_note }}</td>
                    </tr>
                    <tr v-if="product.plant_part">
                      <td>Plant Part Used</td>
                      <td>{{ (product.plant_part) ? product.plant_part.replace(',',', ') : '' }}</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div class="d-md-flex p-0 d-none" style="background-color: transparent;">
                          <button class="btn-default btn-doc-req" style="margin-right:5px;" @click="selectProduct({'recid_product':product.recid_product,'product_ipc':product.product_ipc,'product_name':product.product_name,'moq':product.moq,'qty':''})" :class="selectedProducts.find((item) => item.recid_product === product.recid_product) ? 'selected' : ''"><i class="fa fa-cart-plus" aria-hidden="true"></i> Request Offer </button>
                          <button class="btn-default btn-doc-req" style="margin-right:5px;" @click="selectSample({'recid_product':product.recid_product,'product_ipc':product.product_ipc,'product_name':product.product_name,'moq':product.moq,'qty':'15'})" :class="selectedSamples.find((item) => item.recid_product === product.recid_product) ? 'selected' : ''"> <i class="fa-solid fa-bottle-droplet"></i> Request Sample </button>
                          <button class="btn-default btn-doc-req" data-bs-toggle="dropdown"><i class="fa fa-file"></i> Request Documents <i class="fa fa-caret-down"></i></button>
                          <div class="dropdown-menu dropdown-doc-req" aria-labelledby="sort-text">
                              <button class="dropdown-item" @click.prevent="requestDocument(product.recid_product,user.email,'TDS',$event)" :disabled="product.customer_document_requests.tds > 0">TDS</button>
                              <button class="dropdown-item" @click.prevent="requestDocument(product.recid_product,user.email,'SDS',$event)" :disabled="product.customer_document_requests.sds > 0">SDS</button>
                              <button class="dropdown-item" @click.prevent="requestDocument(product.recid_product,user.email,'DOCS',$event)" :disabled="product.customer_document_requests.docs > 0">DOCS</button>
                              <button class="dropdown-item" @click.prevent="requestDocument(product.recid_product,user.email,'GC',$event)" :disabled="product.customer_document_requests.gc > 0">GC</button>
                          </div>
                        </div>
                        <div class="d-md-none text-center" style="background-color: transparent;">
                          <div class="dropdown">
                            <button class="btn-default btn-doc-req dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Request
                            </button>
                            <div class="dropdown-menu dropdown-doc-req" aria-labelledby="dropdownMenuButton">
                              <h6 class="dropdown-header mt-2">Goods</h6>
                              <a class="dropdown-item" href="#" @click="selectProduct({'recid_product':product.recid_product,'product_ipc':product.product_ipc,'product_name':product.product_name,'moq':product.moq,'qty':''})" :class="selectedProducts.find((item) => item.recid_product === product.recid_product) ? 'selected' : ''">Request Offer</a>
                              <a class="dropdown-item" href="#" @click="selectSample({'recid_product':product.recid_product,'product_ipc':product.product_ipc,'product_name':product.product_name,'moq':product.moq,'qty':'15'})" :class="selectedSamples.find((item) => item.recid_product === product.recid_product) ? 'selected' : ''">Request Sample</a>
                              <div class="dropdown-divider"></div>
                              <h6 class="dropdown-header mt-2">Documents</h6>
                              <a href="#" class="dropdown-item" @click.prevent="requestDocument(product.recid_product,user.email,'TDS',$event)" :disabled="product.customer_document_requests.tds > 0">TDS</a>
                              <a href="#" class="dropdown-item" @click.prevent="requestDocument(product.recid_product,user.email,'SDS',$event)" :disabled="product.customer_document_requests.sds > 0">SDS</a>
                              <a href="#" class="dropdown-item" @click.prevent="requestDocument(product.recid_product,user.email,'DOCS',$event)" :disabled="product.customer_document_requests.docs > 0">DOCS</a>
                              <a href="#" class="dropdown-item mb-2" @click.prevent="requestDocument(product.recid_product,user.email,'GC',$event)" :disabled="product.customer_document_requests.gc > 0">GC</a>
                            </div>
                          </div>
                        </div>

                      </td>
                    </tr>
                  </table>
              </div>
            </div>
            <div class="row mb-5">
              <hr>
                <div class="col-md-12">
                  <div class="dropdown btn-location-mobile d-block d-md-none">
                      <button class="dropdown-toggle form-control" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownDetail">
                      Other Information
                      </button>
                      <ul class="dropdown-menu w-100">
                        <li><a class="dropdown-item" href="#" @click.prevent="showDetail($event,'01')">Other Information</a></li>
                        <li><a class="dropdown-item" href="#" @click.prevent="showDetail($event,'02')">Industry & Application</a></li>
                        <li><a class="dropdown-item" href="#" @click.prevent="showDetail($event,'03')">Packaging Options</a></li>
                        <li><a class="dropdown-item" href="#" @click.prevent="showDetail($event,'04')" v-if="product.moq != ''">MOQ</a></li>
                        <li><a class="dropdown-item" href="#" @click.prevent="showDetail($event,'05')" v-if="product.faq != ''">FAQ</a></li>
                      </ul>
                    </div>

                    <div class="d-none d-md-block">
                      <!-- tabs start -->
                      <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" role="presentation">
                          <a class="nav-tab-link active" id="tab01" data-bs-toggle="tab" href="#tab01_pane" role="tab" aria-controls="tab01_pane" aria-expanded="true">Other Information</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-tab-link" id="tab02" data-bs-toggle="tab" href="#tab02_pane" role="tab" aria-controls="tab02_pane">Industry & Application</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-tab-link" id="tab03" data-bs-toggle="tab" href="#tab03_pane" role="tab" aria-controls="tab03_pane">Packaging Options</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-tab-link" id="tab04" data-bs-toggle="tab" href="#tab04_pane" role="tab" aria-controls="tab04_pane" v-if="product.moq != ''">MOQ</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-tab-link" id="tab05" data-bs-toggle="tab" href="#tab05_pane" role="tab" aria-controls="tab05_pane" v-if="product.faq != null">FAQ</a>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-content">
                      <div class="tab-pane fade show active" id="tab01_pane" role="tabpanel" aria-labelledby="tab01">
                        <div class="row">
                              <div class="col-md-6 product-detail" v-if="product.inci_name != '' && product.column_status.inci_name == 0">
                                  <span>INCI Name</span>
                                  <span>:</span>
                                  <span>{{ product.inci_name }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.iupac_name != '' && product.column_status.iupac_name == 0">
                                  <span>IUPAC Name</span>
                                  <span>:</span>
                                  <span>{{ product.iupac_name }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.CAS_no != '' && product.column_status.CAS_no == 0">
                                  <span>CAS Number</span>
                                  <span>:</span>
                                  <span v-if="product.cas_link != ''"><a class="link" :href="product.cas_link" target="_blank">{{ product.CAS_no }}</a></span>
                                  <span v-else>{{ product.CAS_no }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.EC_no != '' && product.column_status.EC_no == 0">
                                  <span>EC Number</span>
                                  <span>:</span>
                                  <span v-if="product.echa_link != ''"><a class="link" :href="product.echa_link" target="_blank">{{ product.EC_no }}</a></span>
                                  <span v-else>{{ product.EC_no }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.echa_link != '' && product.column_status.echa_link == 0">
                                  <span>ECHA Link</span>
                                  <span>:</span>
                                  <span><a class="link" v-if="product.echa_link" :href="product.echa_link" target="_blank">View</a>
                                  </span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.FEMA_no != '' && product.FEMA_no != 'NA' && product.column_status.FEMA_no == 0">
                                  <span>FEMA Number</span>
                                  <span>:</span>
                                  <span><a class="link" :href="'https://www.femaflavor.org/flavor-library/search?fulltext='+product.FEMA_no " target="_blank">{{ product.FEMA_no }}</a></span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.flavis != '' && product.column_status.flavis == 0">
                                  <span>FLAVIS/FL Number</span>
                                  <span>:</span>
                                  <span>{{ product.flavis }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.coe_no != '' && product.column_status.coe_no == 0">
                                  <span>CoE Number</span>
                                  <span>:</span>
                                  <span>{{ product.coe_no }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.cfr_no != '' && product.column_status.cfr_no == 0">
                                  <span>US FDA (21 CFR)</span>
                                  <span>:</span>
                                  <span>{{ product.cfr_no }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.rifm_id != '' && product.column_status.rifm_id == 0">
                                  <span>RIFM ID</span>
                                  <span>:</span>
                                  <span>{{ product.rifm_id }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.jecfa_number != '' && product.column_status.jecfa_number == 0">
                                  <span>JECFA Number</span>
                                  <span>:</span>
                                  <span>{{ product.jecfa_number }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.jecfa_link != '' && product.column_status.jecfa_link == 0">
                                  <span>JECFA Link</span>
                                  <span>:</span>
                                  <span><a class="link" target="_blank" v-if="product.jecfa_link" :href="product.jecfa_link">View</a></span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.REACH_registration_no != '' && product.column_status.REACH_registration_no == 0">
                                  <span>REACH Regristration Number</span>
                                  <span>:</span>
                                  <span>{{ product.REACH_registration_no }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.iso_spec_number != '' && product.column_status.iso_spec_number == 0">
                                  <span>ISO Specification No</span>
                                  <span>:</span>
                                  <span>{{ product.iso_spec_number }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.iso_spec_link != '' && product.column_status.iso_spec_link == 0">
                                  <span>ISO Specification Link</span>
                                  <span>:</span>
                                  <span><a class="link" v-if="product.iso_spec_link" :href="product.iso_spec_link" target="_blank">View</a>
                                  </span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.halal_cert != '' && product.column_status.halal_cert == 0">
                                  <span>Halal Cert Sequence No</span>
                                  <span>:</span>
                                  <span>{{ product.halal_cert }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.kosher_ukd_id != '' && product.column_status.kosher_ukd_id == 0">
                                  <span>Kosher UKD-ID</span>
                                  <span>:</span>
                                  <span>{{ product.kosher_ukd_id }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.pubchem_link != '' && product.column_status.pubchem_link == 0">
                                  <span>PubChem Link</span>
                                  <span>:</span>
                                  <span><a class="link" v-if="product.pubchem_link" :href="product.pubchem_link" target="_blank">View</a></span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.melting_point != '' && product.column_status.melting_point == 0">
                                  <span>Melting Point</span>
                                  <span>:</span>
                                  <span>{{ (product.melting_point != '') ? product.melting_point+' °C' : '' }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.boiling_point != '' && product.column_status.boiling_point == 0">
                                  <span>Boiling Point</span>
                                  <span>:</span>
                                  <span>{{ (product.boiling_point != '') ? product.boiling_point+' °C' : '' }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.flash_point != '' && product.column_status.flash_point == 0">
                                  <span>Flash Point</span>
                                  <span>:</span>
                                  <span>{{ (product.flash_point != '') ? product.flash_point+' °C' : '' }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.molecular_formula != '' && product.column_status.molecular_formula == 0">
                                  <span>Molecular Formula</span>
                                  <span>:</span>
                                  <span>{{ product.molecular_formula }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.molecular_weight != '' && product.column_status.molecular_weight == 0">
                                  <span>Molecular Weight</span>
                                  <span>:</span>
                                  <span>{{ product.molecular_weight }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.solubility != '' && product.column_status.solubility == 0">
                                  <span>Solubility</span>
                                  <span>:</span>
                                  <span>{{ product.solubility }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.log_p != '' && product.column_status.log_p == 0">
                                  <span>Log P</span>
                                  <span>:</span>
                                  <span>{{ product.log_p ? product.log_p : '-' }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.vapour_pressure != '' && product.column_status.vapour_pressure == 0">
                                  <span>Vapour Pressure</span>
                                  <span>:</span>
                                  <span>{{ (product.vapour_pressure != '') ? product.vapour_pressure+' mm/Hg' : '' }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.yield != '' && product.column_status.yield == 0">
                                  <span>Yield</span>
                                  <span>:</span>
                                  <span>{{ product.yield }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.biodegradability != '' && product.column_status.biodegradability == 0">
                                  <span>Biodegradability</span>
                                  <span>:</span>
                                  <span><span v-for="biodeg in product.biodegradability" :key="biodeg.biodegradability" class="p-0">{{ biodeg.biodegradability }} </span></span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.EC_no != '' && product.column_status.EC_no == 0">
                                  <span>HS Code</span>
                                  <span>:</span>
                                  <span>{{ product.EC_no }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.un_code != '' && product.column_status.un_code == 0">
                                  <span>UN Code - Class - PG</span>
                                  <span>:</span>
                                  <span>{{ product.un_code+'-'+product.un_class+'-'+product.un_pg }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.stability_storage_name != '' && product.column_status.stability_storage_name == 0">
                                  <span>Stability & Storage</span>
                                  <span>:</span>
                                  <span>{{ product.stability_storage_name }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.stability_storage_notes != '' && product.column_status.stability_storage_notes == 0">
                                  <span>Stability & Storage Notes</span>
                                  <span>:</span>
                                  <span>{{ product.stability_storage_notes }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.shelf_life != '' && product.column_status.shelf_life == 0">
                                  <span>Shelf Life</span>
                                  <span>:</span>
                                  <span>{{ product.shelf_life }}</span>
                              </div>
                              <div class="col-md-6 product-detail" v-if="product.relevant_links">
                                  <span>Relevant URL</span>
                                  <span>:</span>
                                  <span>
                                    <ul>
                                      <li v-for="(relevant_link,index) in product.relevant_links" :key="index" class="mb-1">
                                      <a :href="relevant_link" class="link" target="_blank">View</a>
                                      </li>
                                    </ul>
                                    
                                  </span>
                              </div>
                        </div>
                        <div class="synonym-section">
                          <span v-for="(synonym,index) in product.synonyms" :key="index">{{ product.product_ipc+' '+product.product_name+' is the same as'+' '+synonym }},</span>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tab02_pane" role="tabpanel" aria-labelledby="tab02">
                        <div class="w-md-50">
                          <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th style="width:70px;text-align:center;">No.</th>
                                  <th>Industry</th>
                                  <th>Application</th>
                                </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(item,index) in product.application" :key="item.recid_ipc_product_application">
                                <td class="text-center">{{ parseInt(index)+1 }}</td>
                                <td>{{ item.industry_name }}</td>
                                <td>{{ item.application_name }}</td>
                              </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tab03_pane" role="tabpanel" aria-labelledby="tab03">
                        <div class="w-md-50">
                          <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th style="width:70px;text-align:center;">No.</th>
                                  <th colspan="2">Packaging Name</th>
                                  <th class="text-center">Capacity (kg)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(pack, index) in product.packagings" :key="pack.recid_product_packaging">
                                    <td class="text-center" style="vertical-align: middle;">
                                      {{ parseInt(index)+1 }}
                                    </td>
                                    <td class="text-center" style="vertical-align: middle;border-right:none !important;">
                                        <img v-if="pack.image" :src="imageUrl+'img/packaging/' + pack.image" class='thumbnail-packaging'>
                                        <div v-else class="packaging-icon"><i class="fa fa-archive" aria-hidden="true"></i></div>
                                    </td>
                                    <td style="vertical-align: middle; border-left:none;">
                                        {{ pack.name }}
                                    </td>
                                    <td class="text-center" style="vertical-align: middle;">
                                        {{ parseFloat(pack.capacity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
                                    </td>
                                </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tab04_pane" role="tabpanel" aria-labelledby="tab04" v-if="product.moq != ''">
                        <div class="w-md-50" v-html="nl2br(product.moq)">
                          
                        </div>
                      </div>

                      <div class="tab-pane fade" id="tab05_pane" role="tabpanel" aria-labelledby="tab05" v-if="product.faq != null">
                        <div class="">
                          <FaqIpcElement :sources="product.recid_masking"/>
                        </div>
                      </div>
                    </div>
                    <!-- tabs end-->
                </div>
            </div>
            <div class="row mb-5">
              <hr>
              <div class="col-md-12 mb-3 mt-2" v-if="relatedProducts">
                <h4>Related products</h4>
              </div>
              <div class="col-md-12 text-center" v-if="loadingRelatedProducts">
                  <div class="loading mt-5">
                      <img src="@/assets/img/loading.gif" >
                  </div>
              </div>
              <div class="col-md-12">
                <table class="table" v-if="relatedProducts" id="relatedProductTable">
                    <thead>
                      <tr>
                        <th style="cursor: pointer;" @click="sortTable('relatedProductTable',0)">Product Code <i class="fas fa-sort float-end"></i></th>
                        <th style="cursor: pointer;" @click="sortTable('relatedProductTable',1)">Product Name <i class="fas fa-sort float-end"></i></th>
                        <th style="cursor: pointer;" @click="sortTable('relatedProductTable',2)">Category <i class="fas fa-sort float-end"></i></th>
                        <th style="cursor: pointer;" @click="sortTable('relatedProductTable',3)">Olfactive Description <i class="fas fa-sort float-end"></i></th>
                        <th style="cursor: pointer;" @click="sortTable('relatedProductTable',4)">Product Form <i class="fas fa-sort float-end"></i></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="related in relatedProducts" :key="related.product_ipc">
                          <td>{{ related.product_ipc }}</td>
                          <td>{{ related.product_name }}</td>
                          <td style="text-transform: capitalize;">{{ related.product_category_name.toLowerCase() }}</td>
                          <td style="max-width:200px;">{{ related.olfactory_profile }}</td>
                          <td>{{ related.product_form }}</td>
                          <td style="text-align:right">
                            <router-link class="btn-detail d-none d-md-inline" :to="'/product-detail/'+related.slug">Detail</router-link>
                            <router-link class="btn-detail d-block d-md-none" :to="'/product-detail/'+related.slug"><i class="fa fa-list"></i></router-link>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
          
        </div>

        <!-- Button trigger modal -->
        <button type="button" id="trigerModalDocument" class="btn d-none" data-bs-toggle="modal" data-bs-target="#modalDocument">open</button>
        <!-- Modal document request -->
        <form method="POST" @submit.prevent="submitDocumentRequest()" id="form-document-request">
            <div class="modal fade" id="modalDocument" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="text-center doc-request-head">
                            Log In to our <a href="https://portal.vanaroma.com/login" target="_blank"><i>Customer Portal</i></a>, or<br>
                            complete the below form
                        </div>
                        <div class="modal-header text-center">
                            <h5 class="modal-title w-100">Document Access Request</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group modal-form mb-3">
                                        <label>Email</label>
                                        <input type="email" class="form-control" name="email" placeholder="Email" v-model="userForm.email" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                         <label>Full Name</label>
                                        <input type="text" class="form-control" name="full_name" placeholder="Full Name" v-model="userForm.full_name" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Department</label>
                                        <input type="text" class="form-control" name="department" placeholder="Department" v-model="userForm.department" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Company</label>
                                        <input type="text" class="form-control" name="company" placeholder="Company" v-model="userForm.company" required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group modal-form mb-3">
                                            <label>Industry</label>
                                        <input type="text" class="form-control" name="industry" placeholder="Industry" v-model="userForm.industry" required>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Country</label>
                                        <select class="form-control" name="country" v-model="userForm.country" @change="selectCode()">
                                            <option value="">Select Country</option>
                                            <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Phone / Whatsapp</label>
                                        <div class="row">
                                            <div class="col-3">
                                                <select class="form-control" name="phone_code" v-model="userForm.phone_code">
                                                    <option value="">Code</option>
                                                    <option v-for="country in countries" :key="country.code" :value="country.dial_code">{{ country.dial_code }}</option>
                                                </select>
                                            </div>
                                            <div class="col-9">
                                                <input type="text" class="form-control" name="phone" placeholder="number" v-model="userForm.phone" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group modal-form mb-3">
                                        <label>Company Website</label>
                                        <input type="text" class="form-control" name="website" placeholder="https://" v-model="userForm.website" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <input type="hidden" class="form-control" v-model="user.document_type" name="document_type" id="document_type">
                                <input type="hidden" class="form-control" v-model="user.recid_product" name="recid_product" id="recid_product">
                            </div>
                            <button type="submit" class="btn btn-outline-darkgrey w-100" id="btn-submit-document-request">Send document request</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="whatsapp-btn">
          <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
          <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
      </section>
  </div>
</template>
<script>
import axios from "axios";
import validator from 'validator';
import countriesJson from '@/assets/countries.json'
import FaqIpcElement from '@/components/FaqIpcElement.vue'
import { mapActions,mapGetters } from "vuex";

export default {
  name: 'ProductDetail',
  data() {
        return {
          product:[],
          relatedProducts:'',
          isLoading:false,
          loadingRelatedProducts:false,
          emptyResults:false,
          getTry:0,
          tryRelated:0,
          imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
          userForm:{
              email:'',
              full_name:'',
              department: '',
              company:'',
              website:'',
              phone:'',
              country:'',
              industry:'',
              phone_code:''
          },
          request_document_type:'',
          request_recid_product:'',
          countries:countriesJson,
          apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
          //apiUrl:'https://api.vanaroma.com',
        }
  },
  watch: {
        product:function(val){
            if(Object.keys(val).length === 0 && this.getTry < 5){ //max attemp 5
                setTimeout(() => {
                    this.getProduct()
                    this.getTry++
                }, 1000);
            }
        },
        slug:function(){
         this.getProduct()
        }
  },
  computed:{
        slug(){
            return this.$route.params.slug;
        },
        ...mapGetters({
            selectedProducts:"products/selectedProducts",
            user:"products/user",
            selectedSamples:"products/selectedSamples",
        }),
  },
  methods:{
    alertSuccess() {
          this.$swal(
            {
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Your request is already send',
            showConfirmButton: false,
            timer: 2500
            }
        );
    },
    capitalize(s){
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    nl2br (str) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    setProduct(data) {
        this.product = data;
        this.isLoading=false;
        let oldScript= document.getElementById('popoverJS');
        if(oldScript){
            oldScript.remove()
        }
        let popoverJS = document.createElement('script')
        popoverJS.setAttribute('id', 'popoverJS')
        popoverJS.setAttribute('src', '/assets/js/reinit.js')
        document.body.appendChild(popoverJS)
    },
    showDetail(e,tab){
      let text = e.target.text;
      document.getElementById('dropdownDetail').innerHTML=text;
      let btn=document.getElementById('tab'+tab);
      btn.click();
    },
    requestDocument(product,email,type,event){
        event.target.disabled=true;
        this.request_recid_product=product
        this.request_document_type=type
        if(email == '' || email == null){
            let btn=document.getElementById('trigerModalDocument');
            btn.click();
        }else{
            this.sendDocumentRequest();
        }
    },
    submitDocumentRequest(){
        if(validator.isEmail(this.userForm.email)){
            let closeBtn=document.getElementById("closeModalDocument");
            this.setUser(this.userForm);
            this.sendDocumentRequest();
            closeBtn.click();
        }else{
            this.$swal(
                {
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Failed, Email is not Valid',
                showConfirmButton: false,
                timer: 2500
                }
            );
        }
    },
    sendDocumentRequest(){
        document.getElementById('btn-submit-document-request').disabled=true;
        let formdata = new FormData();
        formdata.append("document_type", this.request_document_type);
        formdata.append("recid_product", this.request_recid_product);
        formdata.append("email", this.user.email);
        formdata.append("name", this.user.full_name);
        formdata.append("company", this.user.company);
        formdata.append("website", '');
        formdata.append("phone", '');
        formdata.append("designation", '');
        formdata.append("ip_address", '');
        formdata.append("function", this.user.department);
        formdata.append("website", this.user.website);
        formdata.append("phone", this.user.phone);
        formdata.append("phone_code", this.user.phone_code);
        formdata.append("country", this.user.country);
        formdata.append("industry", this.user.industry);
        formdata.append("status", 0);
        formdata.append("type", 0);
        let bodyContent =  formdata;
        let reqOptions = {
            url: this.apiUrl+"/webx/c_customer_document_request",
            method: "POST",
            data: bodyContent,
        }      
        axios.request(reqOptions)
            .then((response) => {
                this.isLoading=false;
                if(response.data.data.status == 'success'){
                    this.alertSuccess();
                }else{
                    console.log(response.data.data.status);
                }
                document.getElementById('btn-submit-document-request').disabled=false;
            })
            .catch((error) => console.log(error));
        
    },
    getProduct(){
      this.isLoading=true
      let formdata = new FormData();
      formdata.append("product", this.$route.params.slug);
      formdata.append("requestor_email", this.user.email);
      let bodyContent =  formdata;
      let reqOptions = {
          url: this.apiUrl+'/webx/r_product_detail',
          method: "POST",
          data: bodyContent,
      }      
      axios.request(reqOptions)
          .then((response) => {
            if(response.data.data == undefined){
                console.log('retrying to get data product')
                if(this.getTry < 5){
                    setTimeout(() => {
                        this.getProduct()
                        this.getTry++
                    }, 1000);
                }
            }else{
              if(Object.keys(response.data.data).length !== 0){
                this.setProduct(response.data.data)
                // Made this condition if the product is undisplayed on web, it will redirect to 404 not found page
                if(this.product.web_display == 1) {
                  document.title = "Product Not Found";
                  this.$router.push({ name: 'page-not-found' });
                } else {
                  document.title = this.product.product_name + ' - Van Aroma';
                }
                this.getRelatedProducts(this.product.recid_product_sources)
              }else{
                this.isLoading=false;
                this.emptyResults=true
              }
              // console.log(response)
            }
          })
          .catch((error) => {
            console.log(error)
            this.setProduct([])
          });
    },
    getRelatedProducts(source){
      this.loadingRelatedProducts=true
      let formdata = new FormData();
      formdata.append("source", source);
      let bodyContent =  formdata;
      let reqOptions = {
          url: this.apiUrl+'/webx/r_product_by_source',
          method: "POST",
          data: bodyContent,
      }      
      axios.request(reqOptions)
          .then((response) => {
            if(response.data.data == undefined){
              console.log('Retrying to get related products')
              if(this.tryRelated < 5){
                    setTimeout(() => {
                        this.getRelatedProducts(source)
                        this.tryRelated++
                    }, 1000);
              }else{
                this.loadingRelatedProducts=false
              }
            }else{
              if(Object.keys(response.data.data).length !== 0){
                this.loadingRelatedProducts=false
                this.relatedProducts=response.data.data
              }
            }
          })
          .catch((error) => {
            console.log(error)
            this.relatedProducts=[]
          }
      );
    },
    ...mapActions({
        selectProduct:"products/selectProduct",
        setUser:"products/setUser",
        selectSample:"products/selectSample",
    }),
    selectCode(){
      var country = this.countries.find((country) => country.name === this.userForm.country);
      this.userForm.phone_code = country.dial_code;
    },
    sortTable(el, n) {
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      table = document.getElementById(el);
      switching = true;
      dir = "asc";
      while (switching) {
        switching = false;
        rows = table.rows;
        for (i = 1; i < (rows.length - 1); i++) {
          shouldSwitch = false;
          x = rows[i].getElementsByTagName("TD")[n];
          y = rows[i + 1].getElementsByTagName("TD")[n];
          if (dir == "asc") {
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          } else if (dir == "desc") {
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          switchcount++;
        } else {
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }
    }
  },
  components:{
    FaqIpcElement,
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', '/assets/js/main.js')
      document.body.appendChild(mainJS)
      this.getProduct()
  }
}
</script>
<style scoped>
  span{
    background-color: #fff;
  }
  .tab-content {
      background-color: #f8f9f9;
      position: relative;
  }
  .nav-tabs .nav-tab-link.active {
      background-color: #f8f9f9;
  }
  .nav-tabs .nav-tab-link{
    background-color: #fff;
    text-align: center;
    padding: 30px 30px;
    min-width: 165px;
    border-radius: 0;
    margin-right: 4px;
    color: #28303e !important;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .1em;
    border: none;
    background: 0 0;
  }
  .nav-tabs .nav-item {
      margin-bottom: 20px;
      margin-top: 50px;
  }
  .link{
    color: #27d2a3;
    padding: 0;
    background-color: transparent;
  }
  .img-product-box{
    max-width: 400px;
    max-height: 400px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0;
  }
  .product-img{
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
    object-fit: cover;
    border-radius: 5 !important;
  }
  .reach-icon {
    width: 60px;
    height: 60px;
    bottom: -35px;
  }

  table {
    color: #848c9b;
  }
  /* Style the list */
  ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    margin-bottom: 3rem;
    background-color: #f2f4f4;
  }

  /* Display list items side by side */
  ul.breadcrumb li {
    display: inline;
    font-size: 18px;
    color: #5a606b;
  }

  /* Add a slash symbol (/) before/behind each list item */
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: #848c9b;
    content: "/\00a0";
  }

  /* Add a color to all links inside the list */
  ul.breadcrumb li a {
    color: #27d2a3;
    text-decoration: none;
  }

  /* Add a color on mouse-over */
  ul.breadcrumb li a:hover {
    color: #0e8a69;
  }
  /* .product-title{
    font-weight: bold;
  } */
  .table-info tr td:first-child{
    font-weight: bold;
  }
  .btn-doc-req{
    width: auto;
    padding: 10px !important;
    font-size: 12px !important;
  }
  .dropdown-doc-req{
    padding: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  }
  .dropdown-doc-req .dropdown-item{
    margin-top: -1px;
  }
  .product-detail span{
    display: table-cell;
    padding: 10px;
    background-color: transparent !important;
  }
  .product-detail span:first-child{
    width: 220px;
    font-weight: bold;
  }
  @media (max-width: 500px) {
    .table-info tr td:first-child{
      width: 150px;
    }
    .table,.product-detail{
      font-size: 14px;
    }
    .product-detail span:first-child{
      width: 150px;
      font-weight: bold;
    }
    .tab-content {
        padding: 15px !important;
    }
    .btn-doc-req{
      width: 150px;
    }
    
    ul.breadcrumb li {
      font-size: 16px;
    }
    .img-product-box{
      max-width: 300px;
      max-height: 300px;
    }
    .product-title-mobile{
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;
    }
    .product-title-mobile h4{
      font-size: 14px;
      margin-bottom: 0;
    }
    .product-title-mobile i{
      color: #848c9c;
      display: block;
      line-height: 1.4;
    }
    .dont-copy{
      margin-top: -40px;
    }
    .reach-icon{
      width: 30px;
      height: 30px;
      bottom: 10px;
    }
    .product-title-section{
      position: sticky;
      top: 55px;
      background-color: #fff;
      z-index: 9;
      padding-top: 15px;
    }
    .dropdown-item{
      margin-top: 0;
    }
    .dropdown-item.selected{
      color: #ccc;
    }
  }
  .loading img{
    width: 60px;
    height: 20px;
    object-fit: cover;
    margin: auto;
  }
  .btn-detail{
     font-size: 12px !important;
     padding: 5px 15px !important;
     border: 1px solid #000;
  }
  .table th:first-child{
    width: 150px;
  }
  #relatedProductTable i{
    font-size: 12px;
    margin-top: 5px;
  }
  .synonym-section{
    background-color: transparent;
    line-height: 0.7;
    position: absolute;
    widows: 100%;
  }
  .synonym-section span{
    font-size: 9px;
    color: transparent;
    background-color: transparent;
  }
  @media (max-width: 500px) {
    .table th:first-child{
      width: 120px;
    }
  }
</style>