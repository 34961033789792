<template>
   <div class="mt-5">
     <section class="pb-150 mb-5">
        <div class="container dont-copy">
          <div class="row mt-5 mb-5">
            <div class="page-title mt-5">
                <h3>PATCHOULI</h3>
                <h5>Pogostemon cablin (Blanco) Benth.</h5>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6">
                        <img :src="imageUrl+'new_website/images/botanical-source/patchouli-indonesia-1.jpg'" class="img-source p-4" alt="patchouli oil indonesia">
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-5">Cultivation of Patchouli in Indonesia</h4>
                        <p>Indonesia is the largest producer of Patchouli oil, contributing to over 80% of the global supply (1,000 - 1,200 MT).</p>
                        <p>Patchouli is a perennial aromatic herb species that thrives in warm, tropical climates. This makes Indonesia a perfect place to cultivate it. Over the years, the cultivation of  Patchouli has shifted across Indonesia's various islands, and Sulawesi is currently where most of the Patchouli is farmed.
                        </p>
                        <p class="excerpt"><i>Interesting Fact: Van Aroma is the world's single largest producer and exporter of Patchouli Oil (~850 MT per annum).</i></p>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5">Historic Shift in Cultivation</h4>
                        <div class="mt-2">
                            <div class="owl-carousel owl-theme" data-draggable="true" data-dots="false" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-custom" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item">
                                    <img :src="imageUrl+'new_website/images/botanical-source/patchouli-indonesia-3.jpeg'" alt="patchouli oil indonesia">
                                </div>
                                <div class="item">
                                    <img :src="imageUrl+'new_website/images/botanical-source/pacthouli-indonesia-4.jpeg'" alt="patchouli oil indonesia">
                                </div>
                                <div class="item">
                                    <img :src="imageUrl+'new_website/images/botanical-source/patchouli-indonesia-2.jpeg'" alt="patchouli oil indonesia">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 mb-5 text-center">Production</h4>
                        <div class="row">
                            <div class="col-md-4 p-3 text-center">
                                <div class="chart" data-size="200" data-percent="20" data-line="4" data-bgcolor="#e5e5e5" data-trackcolor="#27d2a3" data-speed="3200">
                                    <div class="chart-meta">
                                        <strong class="percent color-darkgrey">10</strong>
                                        <h4 class="text-uppercase mt-3 thin">SUMATRA</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-3 text-center">
                                <div class="chart" data-size="200" data-percent="80" data-line="4" data-bgcolor="#e5e5e5" data-trackcolor="#27d2a3" data-speed="3200">
                                    <div class="chart-meta">
                                        <strong class="percent color-darkgrey">2</strong>
                                        <h4 class="text-uppercase mt-3 thin">JAVA</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-3 text-center">
                                <div class="chart" data-size="200" data-percent="0" data-line="4" data-bgcolor="#e5e5e5" data-trackcolor="#27d2a3" data-speed="3200">
                                    <div class="chart-meta">
                                        <strong class="percent color-darkgrey">88</strong>
                                        <h4 class="text-uppercase mt-3 thin">SULAWESI</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5 mb-5">
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <h4 class="mt-5 mb-3">Why has Patchouli cultivation migrated across these regions?</h4>
                                <p>Patchouli as a crop is fairly demanding on the soil, which is why it cannot be cultivated on the same patch of land for very long. There are certain components and nutrients in the soil needed for a healthy yield and oil composition which 
                                get depleted over time. This is why the plantations tend to move from one place to another. Patchouli adapts itself to a wide range of soil conditions. It requires deep, well-drained, fertile, slightly acidic, deep loamy soil, rich in humus and nutrients. The pH of the soil should range from 5.5 to 7.5 for ideal growth.</p>
                                <p>It takes a long time for the soil to replenish its nutrients to be good enough to re-cultivate Patchouli and yield an optimum quality. Hence, farmers often intercrop Patchouli with plants like Cocoa or Palm as alternatives should one crop not support them for their livelihoods.</p>
                            </div>
                            <div class="col-md-6">
                                <p class="mt-5">This migration of the Patchouli crop has resulted in oils that have varying Patchoulol levels, acid values, colour and olfactive profile.</p>
                                <img :src="imageUrl+'new_website/images/botanical-source/patchouli.svg'" alt="patchouli oil indonesia">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4 class="mt-5 mb-5 text-center">How do we at Van Aroma source our Patchouli Oil?</h4>
                        <p class="text-center">
                            We source all our Patchouli Oil from partner farmers and pre-approved accumulators who harvest and process the crop through steam distillation to produce a crude form of Patchouli Oil. Our closely knit network of farmers and distillers allows for complete traceability of every drop down to source. 
                             <br>PATCHOULI OIL DISTILLATION IN 10 STEPS</p>
                        <div class="mt-5">
                            <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step1.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 1 <br>BABY SEEDLINGS</h5>
                                                <p>Cuttings are rooted in a fully equipped nursery with ample amounts of shade, and water supply.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step2.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 2 <br>PREPARATIONS FOR PLANTING</h5>
                                                <p> Before planting, holes are dug up for the seedlings. These holes are of consistent distance from each other.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step3.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 3 <br>PLANTING SEEDLINGS</h5>
                                                <p>The seedlings are planted within an area of 45 x 45 cm on raised beds in the plains and 60 x 45 cm on sloping terrain. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step4.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 4 <br>PLANTATION</h5>
                                                <p>Plant growth is continuous as all parts of the plant cannot attain harvestable age at the same stage. Therefore, the branches that are 70-90 days old are cut leaving 2-3 active buds or new shoots for regeneration.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step5.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 5 <br>HARVESTED LEAVES</h5>
                                                <p>Patchouli is harvested using the selection method. In this method, crops are partially harvested after 70-90 days of planting.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step6_2.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 6 <br>CUTTING LEAVES FOR DRYING</h5>
                                                <p>After 70-90 days of planting the first batch, regular harvesting is done by cutting only the selected matured branches.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step7_0.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 7 <br>DRYING</h5>
                                                <p>After harvesting, the fresh leaves and stems aree spread out for drying under shade for 7-10 days. The thickness of the leaves should not be more than 2 inches. Flipping the leaves once a day is necessary to avoid fermentation and for uniform drying.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step8_0.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 8 <br>DRIED LEAVES ARE TRANSPORTED TO DISTILLATION UNITS</h5>
                                                <p>Once the leaves have dried completely, they are sent for distillation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step9_0.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 9 <br>DISTILLATION</h5>
                                                <p>Shade dried and cured leaves are subjected to steam distillation for a span of 8-10 hours in order to obtain the volatiles from the Patchouli leaves.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/step10_0.jpeg'" alt="patchouli oil indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>STEPS 10 <br>FILTRATION</h5>
                                                <p>The oil procured from distillation is further filtered and refined to produce different grades of Patchouli Oil.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item steps">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <img :src="imageUrl+'new_website/images/botanical-source/patchouli-oil-indonesia.jpeg'" alt="Patcholi Oil Indonesia">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="caption">
                                                <h5>PATCHOULI OIL</h5>
                                                <p>Cuttings are rooted in a fully equipped nursery with ample amounts of shade.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <FaqElement source="221787f2-2da1-496b-8eb2-8514ddef2c81"/>

        <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Patchouli Product List</h4>
                    </div>
                    <ProductList source="221787f2-2da1-496b-8eb2-8514ddef2c81"/>
                </div>
            </div>
        </div>
        <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
     </section>
   </div>
</template>
<style src="@/assets/css/source-detail.css"></style>

<script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
export default {
  name: 'PatchouliPage',
    data(){
    return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
    mounted() {
        let oldScript=document.getElementById('mainJS');
        if(oldScript){
        oldScript.remove();
        }
        let mainJS = document.createElement('script')
        mainJS.setAttribute('id', 'mainJS')
        mainJS.setAttribute('src', 'assets/js/main.js')
        document.body.appendChild(mainJS)

        let oldPopover= document.getElementById('popoverJS');
        if(oldPopover){
            oldPopover.remove()
        }
        let popoverJS = document.createElement('script')
        popoverJS.setAttribute('id', 'popoverJS')
        popoverJS.setAttribute('src', 'assets/js/reinit.js')
        document.body.appendChild(popoverJS)

        document.title = 'Patchouli Oil Indonesia - Van Aroma';
    }
}
</script>
