<template>
    <div class="mt-5">
      <section class="pb-150 mb-5">
         <div class="container dont-copy">
           <div class="row mt-5 mb-5">
             <div class="page-title mt-5">
                 <h3>COCOA EXTRACT</h3>
                 <h5>THEOBROMA CACAO</h5>
             </div>
           </div>
           <div class="row justify-content-center">
             <div class="col-md-10">
                 <div class="row">
                     <div class="col-md-6"><br>
                         <img :src="imageUrl+'new_website/images/botanical-source/cocoa_content1.jpg'" class="img-source p-4" alt="cocoa extract indonesia">
                     </div>
                     <div class="col-md-6">
                        <h4 class="mt-5">Introduction/Cultivation of Cocoa</h4>
                        <p>INTERESTING FACT: INDONESIA GREW ALMOST NO COCOA PRIOR TO THE EARLY 1980S.</p>
                        <p align="left">Indonesia's cocoa Industry is ripe for growth because of the support it has seen from regulators and the government. Over the years most of the produce was exported as raw cocoa beans. More recently the government has encouraged value addition and imposed export taxes on unprocessed raw material. 
                             At Van Aroma, we specialize in producing cocoa extracts using food grade alcohol. The extract is then concentrated into fine liquid cocoa with a strong chocolaty aroma, chocolate color and deep, rich flavour. Our cocoa extract is Halal certified, and has a significant advantage over cocoa powder due to its solubility, thus simplifying its use as an ingredient in cocoa formulations.. 
                        </p>
                     </div>
                     <div class="col-md-6"><br>
                        <h4 class="mt-5">BRIEF INSIGHT.</h4>
                        <p>Ginger is grown in Java and South Sumatra, and the oil obtained through hydro distillation or stem distillation</p>
                        <p align="left">There are only a few geographical zones in the world where cocoa trees can grow. They come from warm, wet climate. Indonesia is the world's third largest producer of cocoa with ~849,875 MT A YEAR (2016), most of which comes from the island of Sulawesi.</p>
                     </div>
                     <div class="col-md-6">
                        <br><br>
                        <img :src="imageUrl+'new_website/images/botanical-source/cocoa_region.jpg'" class="img-source p-4" alt="cocoa extract indonesia">

                     </div>

                     <div class="col-md-12 mt-5">
                         <h4 class="mt-5 mb-5 text-center">How do we get our Cocoa Extract?</h4>
                         <p class="text-center" >Not all cocoa is created equal! 
                        <br>Our cocoa extract process begins with the careful process of selecting the right beans. It requires gentle handling and processing of the cocoa bean, from harvesting it at a farm through final delivery of the product, it is critical to ensure preservation of cocoa's rich odor and flavor profile.</p>
                         <div class="mt-5">
                             <div class="owl-carousel owl-theme owl-steps" data-draggable="true" data-dots="true" data-nav="true" data-loop="true" data-navclass="owl-nav owl-nav-steps" data-margin="0" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/cocoa_1.jpg'" alt="cocoa extract indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 1 <br>PLANTATION</h5>
                                                 <p>Cocoa trees begin to bear fruit when they are three to four years old. Only a small proportion of the flowers develop into fruits over a period of about five months. The trees are carefully trimmed so that pods can be harvested easily.   </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/cocoa_2.jpg'" alt="cocoa extract indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 2 <br>HARVESTNG THE FRUIT</h5>
                                                 <p>Cocoa pods are harvested through a very labour-intensive process. During peak season, ripe fruits are gathered every few weeks.   </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/cocoa_3.jpg'" alt="cocoa extract indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 3 <br>COLECTING THE SEEDS/BEANS</h5>
                                                 <p>The pods are split open by hand, and the beans are removed.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/cocoa_4.jpg'" alt="cocoa extract indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                 <h5>STEPS 4 <br>FERMENTATION</h5>
                                                 <p>The beans are surrounded by plantation leaves spread out on the ground. The heap is covered with more leaves and left for 5-6 days, regularly turned to ensure even fermentation.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/cocoa_5.jpg'" alt="cocoa extract indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 5 <br>DRYING AND BAGGING</h5>
                                                 <p>The beans are spread on mats and dried under the sun. The dried beans are packed into sacks and transported to Van Aroma's facility.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/cocoa_6.jpg'" alt="cocoa extract indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 6 <br>GRINDING</h5>
                                                 <p>The dried beans are ground into a coarse powder in preparation for extraction.        </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 
                                 <div class="item steps">
                                     <div class="row">
                                         <div class="col-md-8">
                                             <img :src="imageUrl+'new_website/images/botanical-source/SolventExtractor_2-1.jpg'" alt="cocoa extract indonesia">
                                         </div>
                                         <div class="col-md-4">
                                             <div class="caption">
                                                <h5>STEPS 7 <br>SOLVENT EXTRACTION</h5>
                                                 <p>Extract is obtained through solvent extraction of the ground cocoa beans.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
 
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
           </div>
         </div>

         <FaqElement source="cf54fcdd-d168-4198-aeae-bfd0afb15b6b"/>

         <div class="mt-5 py-100">
            <div class="container">
                <div class="row justify-content-center mt-5 mb-5">
                    <div class="col-md-10">
                        <h4 class="mt-5 mb-3">Cocoa Product List</h4>
                    </div>
                    <ProductList source="cf54fcdd-d168-4198-aeae-bfd0afb15b6b"/>
                </div>
            </div>
         </div>
         <div class="whatsapp-btn">
            <a class="va-icon" href="https://wa.me/14124632627" target="_blank"><span class="circle"><img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/img/cs.svg"></span></a>
            <a href="https://wa.me/14124632627" target="_blank"> Talk to Experts</a>
        </div>
      </section>
    </div>
 </template>
 <style src="@/assets/css/source-detail.css"></style>
 
 <script>
import ProductList from '@/components/ProductList.vue'
import FaqElement from '@/components/FaqElement.vue'
 export default {
   name: 'CocoaPage',
   data(){
        return{
        imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
        }
    },
    components:{
        ProductList,
        FaqElement
    },
   mounted() {
     let oldScript=document.getElementById('mainJS');
     if(oldScript){
       oldScript.remove();
     }
     let mainJS = document.createElement('script')
     mainJS.setAttribute('id', 'mainJS')
     mainJS.setAttribute('src', 'assets/js/main.js')
     document.body.appendChild(mainJS)
 
     let oldPopover= document.getElementById('popoverJS');
     if(oldPopover){
         oldPopover.remove()
     }
     let popoverJS = document.createElement('script')
     popoverJS.setAttribute('id', 'popoverJS')
     popoverJS.setAttribute('src', 'assets/js/reinit.js')
     document.body.appendChild(popoverJS)
 
     document.title = 'Cocoa Extract - Van Aroma';
   }
 }
 </script>
 