<template>
  <div>
    <section class="page_copyright ls s-py-25" :style="(currentRouteName == 'maps') ? 'background-color: #f5f5fb !important;' : ''">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-12 text-center">
              <p class="color-dark">Copyright &copy; <span class="copyright_year" v-text="year"></span> Van Aroma. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
export default {
  name: 'CopyRight',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
}
</script>