export default {
    namespaced: true,
    state: {
        selectedProducts:(localStorage.getItem('selectedProducts')) ? JSON.parse(localStorage.getItem('selectedProducts')) : [],
        showCartContent : 1,
        user : (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : [],

        selectedSamples:(localStorage.getItem('selectedSamples')) ? JSON.parse(localStorage.getItem('selectedSamples')) : [],
        showSampleContent : 1,
    },
    getters: {
        selectedProducts: (state) => state.selectedProducts,
        showCartContent: (state) => state.showCartContent,
        user: (state) => state.user,

        selectedSamples: (state) => state.selectedSamples,
        showSampleContent: (state) => state.showSampleContent,
    },
    mutations:{
        addSelectedProduct(state,product){
            const selectedProduct = state.selectedProducts.find((item) => item.recid_product === product.recid_product);
            if (!selectedProduct) {
                state.selectedProducts.push(product);
            }else{
                state.selectedProducts = state.selectedProducts.filter((item) => item.recid_product !== product.recid_product);
            }
            localStorage.setItem('selectedProducts', JSON.stringify(state.selectedProducts))
        },
        updateQty(state, item) {
            const selectedProduct = state.selectedProducts.find((product) => product.recid_product === item.id);
            selectedProduct.qty = item.qty;
            localStorage.setItem('selectedProducts', JSON.stringify(state.selectedProducts))
        },
        unselectProduct(state, id) {
            state.selectedProducts = state.selectedProducts.filter((item) => item.recid_product !== id);
            localStorage.setItem('selectedProducts', JSON.stringify(state.selectedProducts))
        },
        showCartContent(state, status) {
            state.showCartContent=status
        },
        clearSelectedProduct(state){
            state.selectedProducts=[]
            localStorage.setItem('selectedProducts', '')
        },
        updateUser(state, data) {
            state.user = data
            localStorage.setItem('user', JSON.stringify(state.user))
        },

        //sample request
        addSelectedSamples(state,product){
            const selectedSample = state.selectedSamples.find((item) => item.recid_product === product.recid_product);
            if (!selectedSample) {
                state.selectedSamples.push(product);
            }else{
                state.selectedSamples = state.selectedSamples.filter((item) => item.recid_product !== product.recid_product);
            }
            localStorage.setItem('selectedSamples', JSON.stringify(state.selectedSamples))
        },
        unSelectSample(state, id) {
            state.selectedSamples = state.selectedSamples.filter((item) => item.recid_product !== id);
            localStorage.setItem('selectedSamples', JSON.stringify(state.selectedSamples))
        },
        showSampleContent(state, status) {
            state.showSampleContent=status
        },
        clearSelectedSample(state){
            state.selectedSamples=[]
            localStorage.setItem('selectedSamples', '')
        },
        updateSampleQty(state, item) {
            console.log(item)
            const selectedSample = state.selectedSamples.find((product) => product.recid_product === item.id);
            selectedSample.qty = item.qty;
            localStorage.setItem('selectedSamples', JSON.stringify(state.selectedSamples))
        },
    },
    actions:{
        selectProduct({ commit }, product) {
            commit("addSelectedProduct", product); 
            var icon = document.getElementById('cartIcon');
            var label = document.getElementById('totalCart');
            
            label.classList.add('popup');
            setTimeout(function(){
                label.classList.remove('popup');
            }, 500);

            setTimeout(function(){
                icon.classList.add('shake');
            }, 500);
            setTimeout(function(){
                icon.classList.remove('shake');
            }, 700);

        },
        updateQty({ commit }, item) {
            commit("updateQty", item);
        },
        unselectProduct({ commit }, id) {
            commit("unselectProduct", id);
        },
        setShowCartContent({ commit }, status) {
            commit("showCartContent", status);
        },
        clearSelectedProduct({ commit }) {
            commit("clearSelectedProduct");
        },
        setUser({ commit }, data) {
            commit("updateUser", data);
        },


        //sample request
        selectSample({ commit }, product) {
            commit("addSelectedSamples", product); 
            var icon = document.getElementById('sampleIcon');
            var label = document.getElementById('totalSample');
            
            label.classList.add('popup');
            setTimeout(function(){
                label.classList.remove('popup');
            }, 500);

            setTimeout(function(){
                icon.classList.add('shake');
            }, 500);
            setTimeout(function(){
                icon.classList.remove('shake');
            }, 700);
        },
        unSelectSample({ commit }, id) {
            commit("unSelectSample", id);
        },
        clearSelectedSample({ commit }) {
            commit("clearSelectedSample");
        },
        setShowSampleContent({ commit }, status) {
            commit("showSampleContent", status);
        },
        updateSampleQty({ commit }, item) {
            commit("updateSampleQty", item);
        },
       
    }
}